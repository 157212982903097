// Which source should API hit
export const API_SOURCE: any = {
	Theme: ['Faq', 'Category', 'Services', 'Jobs', 'Job', 'Blogs', 'BlogsSett', 'SubscribeEmail', 'Blog', 'Comments', 'Comment', 'BookingFaqs', 'FormData'],
	ThemeV3: ['PageData', 'PageStyle', 'HeaderFooter', 'SitePopups', 'SinglePopup', 'BkngFormCustomization', 'SiteData', 'BkngSummayCustomization', 'DesignSett'],
	Trans: ['Languages'],
	Campaign: ['SystemFormContact', 'CampaignContacts'],
	Json: ['EnglishJson', 'ShortFormJson'],
	IpInfo: ['IpInfo'],
	ApiV3: ['FormSett', 'SaveBooking', 'RescBooking', 'RescPrefilledData', 'bookingsByDateRange', 'bookingsForDates', 'holidaysByDateRange', 'applicableProvidersData', 'applicableProviderData', 'applicableProviders', 'CustomerBookingsCount', 'Bookings', 'UserCards', 'MakeDefaultCard', 'Referrals', 'UserGiftCards', 'AllBookingSpots', 'BookingSpots', 'AppLoadNew', 'DeleteCard', 'HoldCard', 'ReleaseCard', 'GiveTip', 'PostponeBooking', 'CancelBooking', 'JobPictures', 'FormServiceCat', 'Customer', 'AdmnStngs', 'Addons', 'Reviews', 'AuthorizePayProfile', 'UpdateBooking', 'UpdateDeclinedCard', 'CancelSettings', 'CancelRecSettings', 'PaymentClientToken', 'PayIntClientSecret', 'ConfirmedPayDetails', 'PaymentIntent', 'GiftCard', 'OnSessionPayment', 'BraintreeNonce', 'SingleFormSett', 'AuthorizationStatus', 'UserAddress', 'SquareCard', 'FeaturesTrial', 'UserNotif', 'Profile', 'OtherContacts', 'Contacts','RatingWithoutLogin', 'BookingRatingV3', 'FormCustomFields', 'UpdateLanguage', 'Signup', 'DuplicateBooking', 'PublicUserCards', 'GiftCards', 'PublicUserAddCard', 'PublicUserBkngCount', 'CustomFieldsForMultipleBkngs', 'PaymentCache', 'PricingParams', 'PostponeDates', 'UpdateCustomer', 'RecurringScheduleFirstBkng', 'DeletePaymentCache', 'BookingDates', 'SecureLink', 'MailChimpMoveToList', 'SaveUserAddress', 'Drive', 'TemplateData', 'UserMeta', 'FormParams', 'ServsAndFreqs', 'FacebookCoupon', 'Login', 'Coupon', 'ServerUTCTime', 'Preload', 'UnVerifiedPrvdr', 'ProviderSignup', 'GiftCardGallery', 'VerifyEmail', 'UpdateUserAddr', 'MakeDefaultAddr', 'DeleteAddr', 'NotRatedBkng', 'Logout', 'ReqResetPassword', 'ResetPassword', 'DeactivateReq', 'PutGiftCards', 'Quote', 'MarkQuoteAsOpen', 'ProvReviews', 'PaymentLogs', 'UnverifiedProvEmail', 'Tax', 'ReferralToken', 'getProvider', 'CustBookings', 'ReferralsBalance', 'ReferredUser', 'SearchQualifiedProviders', 'RecFirstBookingId', 'MerchantLocations'],
	Checklist: ['GetBookingChecklist', 'GetChecklistBuilder', 'GetChecklistValues', 'SaveNote','GetNotes', 'GetBookingUsers', 'DeleteNote','GetChecklistNotes','MarkNotesSeen', 'TagNames', 'GetTaskMedia', 'GetChecklistByBookingId'],
	ApiV5: [],
	Invoice: ['Invoice', 'ChargeCustomInv', 'CustomInvChargeAmt', 'SaveInvContract', 'GenerateInvOtp', 'ValidateOtp', 'InvPaymentLogs', 'InvViewStatus', 'CustInvoices', 'BkngInvTotal', 'BkngInvCharge', 'BkngInvDetails', 'InvTrans', 'DownloadInv'],
	Leads: ['LeadsForm', 'LeadContacts'],
	LocalJson: ['InteriorPageJsons', 'InteriorPopupJsons'],
	BKsyncUrl:['QbOauth']
}

// API urls
export const API_NAMES: any = {
	EnglishJson: '/assets/data/en.json', // Method: Get
	IpInfo: 'ipinfo.php', // Method: Get
	AppLoadNew: 'appload-customer',	// Method: Get
	AdmnStngs: 'admin-settings-customer', // Method: Get
	AllBookingSpots: 'all-booking-spots?customer=true', // Method: Get
	BookingSpots: 'booking-spots', // Method: Get
	Languages: 'languages', // Method: Get
	FeaturesTrial:'features/trial', // Method: Get
	HeaderFooter: 'header-footer', // Method: Get, Params: theme_slug & language
	UpdateCustomer: 'customer', // Method: Put
	Customer: 'customer/P1', // Method: Get, P1: userId
	PageData: 'page/P1', // Method: Get, P1:page id, Params: mode, slug, theme_slug, language, industry, form,
	PageStyle: 'page/P1/style',// Method: Get, P1:page id, Params: mode, slug, theme_slug, language, industry, form,
	Faq: 'faq-listing', // Method: Get, Params: status,sort & language
	Category: 'category', // Method: Get, Params: type & language
	Reviews: 'get-reviews', // Method: Get, Params: limit
	Services: 'services', // Method: Get, Params: status,sort & language
	Jobs: 'job', // Method: Get, Params: language
	Job: 'job/0', // Method: Get, Params: slug & language
	Blogs: 'blog', // Method: Get, Params: status,limit,page,category,sort,s & language
	BlogsSett: 'blog-settings', // Method: Get
	SubscribeEmail: 'subscribe-email', //Method: Post
	Blog: 'blog/0', // Method: Get, Params: slug & language
	Comments: 'comments/P1', // Method: Get, P1: blogId, Params: limit, page, replies & language
	Comment: 'comment', // Method: Post
	Login: 'login', // Method: Post
	Logout: 'user/P1/logout', // Method: Get, P1: userId
	LoginAsUser: 'login-as-user/0/P1', // Method: Post, P1: Token
	LoginWithOtToken: 'login-with-ot-token/P1/P2', // Method: Post, P1: userId, P2: token
	Signup: 'signup', // Method: Post
	VerifyEmail: 'verify-email', // Method: Get, Params: email & id
	VerifyPhone: 'verify-phone', // Method: Get, Params: phone & id
	ReqResetPassword: 'request-reset-password', // Method: Post
	ResetPassword: 'reset-password', // Method: Post
	ReferralToken: 'referral/P1', // Method: Get, P1: Token
	UserCards: 'customer/P1/cards', // Method: Get/Post, P1: userId, Params: location_id, is_giftcard & is_same
	GiftCardGallery: 'gift-card-gallery', //Method: Get
	GiftCard: 'gift-card', // Method: Get, Params: id(giftCard id) / code
	GiftCards: 'gift-cards', // Method: Post, Params: ses_id, campaign_id, contact_id, referrer_source, track_from, sequence_id
	PutGiftCards: 'gift-cards', // Method: Post, Params: ses_id, campaign_id, contact_id, referrer_source, track_from, sequence_id
	UserGiftCards: 'customer/P1/gift-cards', // Method: Get, Params: type, limit & page
	Referrals: 'customer/P1/referrals', //Method: Get, P1: userId, Params: limit & page
	ReferralsBalance: 'customer/P1/referral-balance', //Method: Get, P1: userId
	Addons: 'addons', // Method: Get
	SendReferral: 'customer/P1/send-referral', // Method: Post, P1: userId, Params: source, referrer & referral
	Bookings: 'customer/P1/bookings', //Method: Get, P1: userId, Params: status, limit & page
	PostponeDates: 'booking/P1/postpone', // Method: Get, P1: bookingId
	PaymentLogs: 'booking/P1/payment-logs', // Method: Get, P1: bookingId
	SitePopups: 'popups', // Method: Get, Params: theme_slug & language
	SinglePopup: 'popup/P1', // Method: Get, P1: popup id, Params: theme_slug & language
	BookingRating: 'booking/P1/rating', // Method: Post/Put , Params: P1: bookingId
	BookingRatingV3: 'booking/P1/rating', // Method: Post , Params: P1: bookingId
	GiveTip: 'give-tip', // Method: Post
	DuplicateBooking: 'booking/P1/duplicate', // Method: Get, P1: bookingId
	PostponeBooking: 'booking/P1/postpone-booking', // Method: POST, P1: bookingId, Params: date & refund
	CancelSettings: 'booking/P1/get-cancel', // Method: Get, P1: bookingId, Params: type
	CancelRecSettings: 'booking/P1/get-rec-cancel',  // Method: Get, P1: bookingId
	CancelBooking: 'booking/P1/cancel', // Method: Post, P1: bookingId
	UserAddress: 'customer/P1/address', // Method: Get, P1: userId, Params: zipcode
	SaveUserAddress: 'customer/P1/address', // Method: Post, P1: userId, Params: zipcode
	UpdateUserAddr: 'customer/P1/address/P2', // Method: Put, P1: userId, P2: addressId
	MakeDefaultAddr: 'customer/P1/address/P2/make-default', //Method: Get, P1: userId, P2: addressId
	DeleteAddr: 'customer/P1/address/P2', //Method: Get, P1: userId, P2: addressId
	MakeDefaultCard: 'customer/P1/make-default', //Method: Post, P1: userId
	DeleteCard: 'customer/P1/delete-card', //Method: Post, P1: userId
	Drive: 'customer/P1/drive', // Method: Get/Delete/Post,  P1: userId/driveId, Params: limit & page
	Drives: 'customer/P1/drive', // Method: Get,  P1: userId, Params: limit & page
	OtherContacts: 'profile/P1', // Method: Get,  P1: userId
	Contacts: 'profile/P1/P2', // Method: Get/Delete, P1: userId, P2: ContactId
	Profile: 'profile', // Method: Post/Put
	DeactivateReq: 'customer/P1/request-deactivate', // Method: Post
	EmailNotif: 'notification-templates', //Method: Get, Params: type
	SmsNotif: 'sms-templates', //Method: Get, Params: type
	AppNotif: 'app-notifications', //Method: Get, Params: type
	UserNotif: 'customer/P1/notifications', //Method: Get/Put, P1: userId
	LeadForm: 'lead-form', //Method: Get
	CustomerLead: 'customer-lead', // Method: Post
	BookingFaqs: 'booking-faqs', // Method: Get, Params: language
	FormData: 'form-data', // Method: Post, Params: theme_slug
	Coupon: 'coupon', // Method: Get, Params: code,user_id,bid & language
	SystemFormContact: 'system-form/contact', // Method: Post
	NotRatedBkng: 'not-rated-bookings/P1', // Method: Get, Param: bid/userId, Query params: bid
	RatingWithoutLogin: 'booking/P1/rating-without-login', // Method: Post, P1: bookingId
	UserMeta: 'user-meta/P1', // Method: Get, P1: bookingId, Params: role
	MarkQuoteAsOpen: 'quote/P1/mark-opened', // Method: Get, P1: quoteId
	Quote: 'quote/P1', // Method: Get, P1: quoteId
	IndustryFormSett: 'industry/P1/form-settings', // Method: Get, P1: industryId
	FormSett: 'industry-settings/P1/P2', // Method: Get, P1: industryId, P2: formId, Param: status & bid
	SingleFormSett: 'merchant/form-settings/P1/P2/settings',
	ReferredUser: 'customer/P1/referred-user', // Method: Get, P1: UserId
	MerchantLocations: 'merchant-locations', // Method: Get, Param: type, form_id, industry_id, all & s
	FormCustomFields: 'form-custom-fields', // Method: Post
	CustomFieldsForMultipleBkngs: 'bookings/custom-fields', // Method: POST
	MailChimpMoveToList: 'move-to-list', //Method: Post, (Mailchimp api)
	CampaignContacts: 'contacts/form', // Method: Post, Custom headers
	UpdateDeclinedCard: 'booking/P1/update-declined-card', // Method: Post, P1: bId
	FacebookCoupon: 'fbcoupon', // Method: Get
	StepStatus: 'step-status', // Method: Post
	SearchQualifiedProviders: 'search/qualified-providers', // Method: Post, Param: s: keyword
	Tax: 'get-tax-rate', //Method: Get, Param: location_id, postal_code, state, city, industry_id
	DailyDisc: 'daily-discounts', //Method: Get, Param: start_date, end_date, industry_id, location_id, service_category, customer_id
	RescPrefilledData: 'booking/P1', //Method: Get, P1: bookingId
	RecFirstBookingId: 'rec-first-booking-id/P1', //Method: Get, P1: recurringId
	AvailSett: 'industry-settings-ids/P1/P2', //Method: Get, P1: industryId, P2: formId, Param: status, bId
	getProvider: 'get-provider/P1', //Method: Get, Params: providerid, industry id, form id etc
	SaveRescFeeErrLog: 'debug-resc-fee', // Method: POST
	HoldCard: 'authorize-booking', //Method: POST
	RescBooking: 'booking', //Method: PUT
	Checklist: 'booking-checklist/P1', // Method: GET, P1: bookingId
	JobPictures: 'job-pictures/P1?is_new=true', // Method: GET, P1: bookingId
	SaveBooking: 'booking', // Method: POST, Param: ses_id, campaign_id, contact_id, referrer_source, track_from, sequence_id,  Header: Content-Type, Ip
	ReleaseCard: 'delete-payment-intent', //Method: POST
	holidaysByDateRange: 'holidays/dates', // Method : POST, pass date range. If start date is passed then end date date will of start date+ 6 months
	bookingsByDateRange: 'bookings/dates',	// POST : get bookings in a date range along with other date to check spot availability
	bookingsForDates: 'bookings-for-dates',	// get bookings in a date range along with other date to check spot availability
	applicableProviders: 'applicable-providers',	// Method : POST get applicable providers for the booking based on the parameters selected on the booking
	applicableProvidersData: 'applicable-providers-data',	// Method : POST get applicable providers for the booking based on the parameters selected on the booking
	applicableProviderData: 'applicable-provider-data',	// Method : POST get applicable providers for the booking based on the parameters selected on the booking
	ShortFormJson: '/assets/data/short_form.json', // Method: GET
	BkngFormCustomization: 'booking-form/customizations', // Method: GET, Param: language, industry, form, mode, theme_slug
	BkngSummayCustomization: 'booking-form/summary/customizations', // Method: Get, Param: theme_slug, language, mode, industry, form(industry and form optional)
	CustomerBookingsCount: 'customer/P1/bookings-count', // Method: GET, P1: customerId
	FormServiceCat: 'merchant/form-settings/P1/P2/service-category/P3', // Method: GET, P1: industryId, P2:formId, P3: serviceId
	SiteData: 'site/data', // Method: Get, params: theme_slug
	ProvReviews: 'get-provider-reviews/P1', // Method:Get,  P1: providerId, params: limit
	CheckSameScheduleAvail: 'check-same-schedule-availability', // Method: POST,
	UpdateBooking: 'update-booking/P1', // Method: PUT,  P1: bookingId
	AuthorizePayProfile: 'payment-profile/P1', //Method: Post, P1: customerId
	UpdateLanguage: 'language/P1', //Method: PUT, P1:  customer id
	FormParams: 'form-params', // Method: GET
	ServsAndFreqs: 'servs-freqs', // Method: GET

	// 3DS
	PaymentClientToken: 'payment/client-token/P1', // Method: GET, P1: location id
	PayIntClientSecret: 'payment-intent/secret/P1', // Method: GET, P1: token
	ConfirmedPayDetails: 'payment/P1', // Method: POST, P1: token
	PaymentIntent: 'stripe/payment-intent', // Method: POST
	OnSessionPayment: 'on-session-payment', //Method: POST
	BraintreeNonce: 'braintree/nonce', // Method: POST
	AuthorizationStatus: 'authorization-status',// Method: PUT
	SquareCard: 'square/card',// Method: POST
	PaymentCache:'payment-cache', // Method: POST

	//Checklist
	GetBookingChecklist:'P1/checklist', // Method: GET, P1:BookingId
	GetBookingUsers :"P1/users",//Method = GET P1 = BookingId
	GetChecklistBuilder: 'checklist/P1', // Method: GET, P1:ChecklistId
	GetChecklistValues: 'P1/P2/values', // Method: GET, P1:BookingId, C1:ChecklistId
	GetChecklistNotes: 'P1/P2/notes', // Method: GET, P1:BookingId, C1:ChecklistId
	GetNotes:'P1/P2/P3/note',// Method = GET P1 = BookingId,P2= ChecklistId,P3=provider/merchant/customer
	SaveNote: 'checklist/note', //Method = POST
	DeleteNote:'checklist/note/P1',// Method = DELETE P1 = NoteId
	MarkNotesSeen:'notes',//Method = PUT
	TagNames: 'tag/names',//Method=GET,
	GetTaskMedia:"media/P1/P2/P3",//Method = GET P1 = BookingId,P2 = ChecklistId,P3= TaskId/SubtaskId,
	GetChecklistByBookingId: "P1/P2/checklist", // Method = GET P1 = BookingId,P2= ChecklistId

	// Invoice
	Invoice: 'invoices/P1', // Method = GET, P1 =  Invoice id
	PublicUserCards: 'cards/P1', //Method =  GET, P1 = UserId
	ChargeCustomInv: 'invoice/charge/custom', //Method = POST
	CustomInvChargeAmt: 'custom/amount', //Method  = POST
	SaveInvContract: 'contract', //Method = POST,
	GenerateInvOtp:'inv/P1/generate-otp', // Method = GET, P1: InvId
	ValidateOtp: 'validate-otp', // Method = POST
	InvPaymentLogs: 'invoice/P1/pay-logs', // Method: GET, P1: InvId
	InvViewStatus:'invoices/view-status/P1', // Method: Put, P1: InvId
	CustInvoices: 'customer/P1/invoices', // Method: GET, P1: custId
	BkngInvTotal: 'bookings/amount', // Method: POST
	BkngInvCharge:'invoice/charge/bookings', // Method: POST
	BkngInvDetails: 'bookings/details', // Method: POST
	PublicUserAddCard:'customers/P1/card', // Method:POST, P1: custId
	PublicUserBkngCount: 'customers/P1/bookings-count', // Method: GET, P1: customerId
	InvTrans: 'translations', // Method: GET
	DownloadInv:'pdf/P1', // Method: GET
	PricingParams: 'pricing-parameters', // Method: GET
	RecurringScheduleFirstBkng: 'booking/P1/first', // Method GET, P1 is booking id
	DeletePaymentCache:'cache', // Method: POST
	CustBookings: 'customer-bookings', // Method: POST

	// Leads
	LeadsForm: 'forms/P1', // Method: GET
	LeadContacts: 'system-form/leads', // Method: Post, Custom headers

	// Default JSONS
	InteriorPageJsons: '/assets/data/DefaultInteriorPages/P1.json', // Method: Get
	InteriorPopupJsons: '/assets/data/DefaultInteriorPopups/P1.json', // Method: Get

	BookingDates: 'booking-dates/P1', // Method: GET
	SecureLink: 'secure-link/valid/P1', // Method:GET, P1: Token

	TemplateData: 'template-data', // Method: GET, Query params: slug=service_receipt&booking_id=xxx
	QbOauth: 'qb-oauth2redirect',

	// Design settings
	DesignSett: 'site/design-settings', // Method: GET

	// csrf Token
	Preload: 'preload',
	ServerUTCTime:'utc-timestamp',

	// Provider signup
	UnVerifiedPrvdr: 'unverified-provider', // Method: POST
	ProviderSignup: 'provider-sign-up', // Method: POST,
	UnverifiedProvEmail: 'unverified-provider/P1', //Method: GET
}
