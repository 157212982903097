import { Injectable, Injector } from '@angular/core';
import { Observable, Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Route, Router } from '@angular/router';
// External lib
import { sha512Hash, generateSessionHeaders, getSessionHeaders, encryptGCM, decryptGCM } from 'iron-crypto-pkg';
// Environments
import { environment as env } from 'src/environments/environment';
// Constants
import { SELECTED_MASK, MASKS_OBJ, STR, IMG, DEFAULT_LANG_CODE, PREMIUM_PLANS, ADV_FEATURES, IS_MOBILE, IS_DEV, AUTH_KEY, GROWING_PLANS, GROWING_FEATURES, ROUTE_URLS, ALLOW_OLD_VERSION } from '../Constants';
// Api service
import { ApiServ, APIURL, CookieServ } from './index';
import { LoaderServ } from './Loader.service';
// Interface
// import { APIRes } from '../Interfaces';
// interface csrfTokenApiResp extends APIRes { data: {'X-CSRF-Token': string} | null }

@Injectable({
	providedIn: 'root'
})
export class InitServ {
	// Readonly variables
	readonly appStr: any = STR;
	readonly img: any = IMG;
	readonly imgBase: string = this.APIURL.imgBase;
	readonly baseUrl: string = this.APIURL.baseUrl;
	readonly apiUrl: string = this.APIURL.apiUrl;
	readonly tinymceAPIKey: any = this.APIURL.tinymceAPIKey;
	readonly fileCdn: any = this.APIURL.fileCdn;
	privateCdn: any = this.APIURL.privateCdn;
	readonly routeUrls: any = ROUTE_URLS;
	// Private variables
	private destroy = new Subject<void>();
	private _ipAddress: any;
	private _appData: any;
	private _appAdmnStngs: any;
	private _appBookingSpots: any;
	public _userInfo: any;
	private _bkngCustData: any;
	private _summaryData: any;
	private _socialKeys: any;
	private _languages: any;
	private _headerFooter: any;
	private _themePopups: any;
	private _csrfToken: string = '';
	private _localTimeDiff: number = 0;

	// Local variable
	allServCats: any[] | null = null;
	allFreqs: any[] | null = null;
	// Public variables
	_siteData: any;
	paymentGateway: string = 'stripe';
	allowBillingAddr: boolean = false;
	selectedMask: any = SELECTED_MASK;
	defaultLangCode: string = DEFAULT_LANG_CODE;
	defaultProvLang: string = DEFAULT_LANG_CODE;
	savedLng: string = DEFAULT_LANG_CODE;
	serviceFeeLabel: string = '';
	customTaxLabel: string = '';
	callingCode: string = '';
	theme: string | null = '';
	lngCookieName: string = 'null';
	locationsStatus: boolean = true;
	appRoutes: string[] = [];
	selectedLang: any;
	activeTheme: string = 'simple';
	reRender: boolean = true;
	apiLoadStatus: any = {
		themePopups: false,
		bkngCustData: false,
		servsAndFreqs: false
	};
	public isRTLChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isUserProfile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public userRefBal: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public isLangSet: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
	public setTranslation: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
	public setLogoData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	// Trial features
	public trialFeatures: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	// Getter function
	get appData(): any { return this._appData; }
	get appAdmnStngs(): any { return this._appAdmnStngs; }
	get appLanguages(): any { return this._languages; }
	get appBookingSpots(): Array<[]> { return this._appBookingSpots; }
	get ipAddress(): string { return this._ipAddress; }
	get userInfo(): any { return this._userInfo; }
	get bkngCustData() { return this._bkngCustData; }
	get bkngSummData() { return this._summaryData; }
	get appSocialKeys(): any { return this._socialKeys; }
	get headerFooter(): any { return this._headerFooter; }
	get themePopupsData(): any { return this._themePopups; }
	get siteData(): any { return this._siteData }
	get csrfToken(): string { return this._csrfToken; }
	get timestampTimeDiff(): number { return this._localTimeDiff; }

	public appDynamicRoutes: any = {};
	statusType: string = 'status';
	firstPageData: any = null;
	firstPageSlug: any = null;
	firstPageLang: any = null;
	setProvLangStatus: boolean = false;
	threeDSecure: boolean = false;
	isRatingAllowed: boolean = true;
	ratingAllowedFor: Array<any> = [];
	storeDateFormat: string = "MM/DD/YYYY";

	// user pages urls with new slug
	userPagesRoutes:any = {
		'edit-personal-details': 'profile/edit',
		'info-and-billing':'info',
		'my-drive': 'drive'
	};

	defaultUserPagesRoutes: {[key:string]: string} = {
		'invoice': 'invoice',
		'invoice-payment': 'invoice-payment',
		'invoice-thankyou': 'invoice-thankyou',
		'dashboard': 'dashboard',
		'gift-cards': 'gift-cards',
		'notifications': 'notifications',
		'profile/edit': 'profile/edit',
		'edit-personal-details': 'profile/edit',
		'info-and-billing': 'info',
		'info': 'info',
		'drive': 'drive',
		'my-drive': 'drive',
	}

	// eslint-disable-next-line max-params
	constructor(private apiServ: ApiServ, private APIURL: APIURL, private cookieServ: CookieServ, private router: Router, private injector: Injector, private loader: LoaderServ) {
		this.statusType = IS_MOBILE ? 'mobile_status' : 'status';
		const url = new URL(window.location.href);
		this.lngCookieName = `${this.APIURL.domainName}_language`;
		// Get the theme slug from URL query params
		if (url.searchParams.get('theme')) {
			this.theme = url.searchParams.get('theme');
		}
	}
	/**
	 * Site data
	 * @returns Api Res
	 */
	public appSiteData(): Observable<any> {
		return this.apiServ.callApiWithQueryParams('GET', 'SiteData', { theme_slug: this.theme }).toPromise().then((resp: any) => { this.appSiteDataApiResp(resp)});
	}

	/**
	 * Processes the response from the app site data API and dynamically updates routes.
	 * Steps:
	 * 1. Validates the API response using `checkAPIRes` and checks if the `data` object exists.
	 * 2. Assigns the `data` from the response to the `_siteData` property.
	 * 3. If `page_urls` are provided:
	 *		 a. Strips the leading slash from each route in `page_urls`.
	 *		 b. Adds user-specific pages to the routes that are not already defined.
	 *		 c. Merges the user-specific routes with the app's dynamic routes.
	 *		 d. Updates the application's routing with the new dynamic routes.
	 * 4. Calls a method to retrieve and configure any additional custom routes.
	 * @param {any} resp - The API response containing the app's site data and dynamic routes.
	 * @returns {void}
	 */
	private async appSiteDataApiResp(resp: any): Promise<void>{
		if (!(this.apiServ.checkAPIRes(resp) && resp?.data)) {
			return;
		}
		this._siteData = resp.data;
		if (resp.data?.page_urls) {
			this.appDynamicRoutes = resp.data.page_urls;
			if (this.appDynamicRoutes) {
				this.appDynamicRoutes = this.getAppDynamicRoutes(this.appDynamicRoutes);
			}
			// Add the user pages routes for the accounts that does not exist the page urls.
			this.addUserPages();
			let userRoutes:any = await this.appendUserRoutes(this.userPagesRoutes).then((route) => route);
			this.appDynamicRoutes = { ...this.appDynamicRoutes, ...userRoutes};
			this.changeAppRouting();
		}
		// App custom routes
		this.getAppRoutes();
	}

	private getAppDynamicRoutes(appDynamicRoutes: any) {
		// Remove slash from routes first index
		let routesKeys = Object.keys(appDynamicRoutes);
		if (routesKeys && routesKeys.length > 0) {
			for (let key of routesKeys) {
				let route: any = appDynamicRoutes[key];
				if (route) {
					let url = (route.charAt(0) == '/') ? (route.substr(1)) : route;
					appDynamicRoutes[key] = url;
				}
			}
		}
		return appDynamicRoutes;
	}

	/**
	 * App load
	 * @returns Api Res
	 */
	public appload(): Observable<any> {
		this._appData = null;
		return this.apiServ.callApi('GET', 'AppLoadNew').pipe(takeUntil(this.destroy)).toPromise().then((res: any) => {
			if (this.apiServ.checkAPIRes(res) && res?.data) {
				this._appData = res.data;
				this.checkLocationsStatus();
			}
		});
	}

	/**
	 * Method `currentRouteSlug` returns the current route slug by extracting it from the window
	 * location pathname and performing some string manipulation.
	 * @returns a string value, which is the current route slug of the page.
	 */
	public currentRouteSlug(urlSlug:any = ''): string {
		// let slug: any = window.location.pathname;
		let slug: any = urlSlug;
		slug = slug.replace('/worker/', '');
		if (this.isRouteSlugEmpty(slug) || slug == '/') {
			slug = 'home';
		}

		slug = slug.startsWith('/') ? slug.substring(1) : slug;

		// slug for customer-interior pages
		if(Object.values(this.userPagesRoutes).includes(slug)){
			return Object.keys(this.userPagesRoutes).find((key:string) => this.userPagesRoutes[key] == slug) ?? slug;
		}

		return slug;
	}

	private isRouteSlugEmpty(slug: any): boolean {
		return (!slug || slug == '');
	}

	/**
	 * Method ***"changeCurrentRouteSlug"*** takes a slug as input and returns the corresponding route URL
	 * from a list of route URLs.
	 * @param {string} slug - A string representing the current route slug.
	 * @returns the value of `this.routeUrls[slug]` if a matching route is found. If no matching route is
	 * found, it returns `null`.
	 */
	public changeCurrentRouteSlug(slug: string): string | null {
		if(slug){
			slug = `${slug.split('/')[0]}/`;
			return this.routeUrls[slug] ?? null;
		}
		return null;
	}

	/**
	 * Limited Data
	 * @returns Api Res
	*/
	public pageData(): any {
		// get the current route slug of the current page.
		let slug: any = this.currentRouteSlug(this.router.url);
		//
		if (slug.includes('reschedule-booking')) {
			return null;
		}
		let lang: any = this.cookieServ.getCookie(this.lngCookieName);
		// this is added for child inner pages ie. for 'appointments/**' and 'invoices/**'
		slug = this.changeCurrentRouteSlug(slug) ?? slug;
		// this.firstPageLang = lang ? lang : null;
		let queryParams: any = {slug, language:(lang) ? lang : '', mode: 'live', theme_slug: ''};
		let params: any = new URLSearchParams(window.location.search);
		if (params?.has('theme')) {
			queryParams['theme_slug'] = params.get('theme');
			queryParams['mode'] = 'draft';
		}
		return this.apiServ.callApiWithPathQueryVars('GET', 'PageData', [0], queryParams).pipe(takeUntil(this.destroy)).toPromise().then((resp: any) => this.pageDataApiResp(resp, slug));
	}

	private pageDataApiResp(resp: any, slug: string){
		if (this.isPageData(resp) && !this.setProvLangStatus) {
			this.firstPageData = resp;
			this.firstPageSlug = slug;
		}
	}

	private isPageData(resp: any){
		return this.apiServ.checkAPIRes(resp) && resp?.data;
	}

	/**
	 * App languages
	 * @returns Api Res
	 */
	public loadLanguages(): Observable<any> {
		this._languages = null;
		return this.apiServ.callApi('GET', 'Languages').pipe(takeUntil(this.destroy)).toPromise().then((resp: any) => {
			if(this.apiServ.checkAPIRes(resp) && resp?.data){
				this._languages = resp.data;
			}
		});
	}

	/**
	 * Booking customization data, handle by theme builder
	 * @param isEmpty
	 * @param code
	 * @returns Api Res
	 */
	public async loadBkngCustData(): Promise<any> {
		// await this.appLngCode();
		this._bkngCustData = null;
		this._summaryData = null;
		let queryParams: any = { theme_slug: this.theme, language: this.savedLng, mode: 'live' };
		if (this.theme) {
			queryParams['mode'] = 'preview';
		}
		return this.apiServ.callApiWithQueryParams('GET', 'BkngSummayCustomization', queryParams).pipe(takeUntil(this.destroy)).toPromise().then((resp: any) => { this.bkngCusumApiResp(resp) });
	}

	/**
	 * Processes the response from the booking summary API and structures custom data for further use.
	 * Steps:
	 * 1. Validates the API response using `checkAPIRes` and ensures the `data` object exists.
	 * 2. Updates the `_summaryData` property with the API response data.
	 * 3. Iterates over the `_summaryData` to extract and organize the relevant section data:
	 *		 a. Checks if each key in `_summaryData` has `sections` with data.
	 *		 b. For each section, extracts the section ID and maps the section data to a custom format.
	 *		 c. For each section key, creates a corresponding ID (`key_id`) and processes the section content using the `getText()` method.
	 * 4. The processed custom data is stored in the `_bkngCustData` object.
	 * 5. Updates the `apiLoadStatus['bkngCustData']` flag to indicate that the booking custom data has been successfully loaded.
	 * @param {any} resp - The API response containing booking summary data.
	 * @returns {void}
	 */
	private bkngCusumApiResp(resp: any): void {
		if (!(this.apiServ.checkAPIRes(resp) && resp?.data)) {
			return;
		}
		this._summaryData = resp.data;
		let custData: any = {};
		if (this._summaryData && Object.keys(this._summaryData).length > 0) {
			for (let key in this._summaryData) {
				custData = this.buildBkngCusumData(key, custData);
			}
		}
		this._bkngCustData = custData;
		this.apiLoadStatus['bkngCustData'] = true;
	}

	private buildBkngCusumData(key: any, custData: any){
		if (this.isSummarySec(key)) {
			let sections: any = this._summaryData[key].sections;
			custData[key] = {};
			let parentKey = Object.keys(sections);
			if (parentKey && parentKey?.length > 0) {
				let secId = parentKey[0];
				for (let sec in sections[secId]) {
					let keyId = `${sec}_id`;
					custData[key][keyId] = sections[secId][sec];
					custData[key][sec] = this.getText(sections[secId][sec], this._summaryData[key]?.section_settings, this._summaryData[key]?.content)
				}
			}
		}
		return custData;
	}

	private isSummarySec(key: any): boolean {
		return (this._summaryData[key]?.sections && Object.keys(this._summaryData[key].sections).length > 0);
	}

	/**
	 * Site popups, handle by theme builder
	 * @returns
	 */
	public async loadThemePopups(): Promise<any> {
		this._themePopups = null;
		let queryParams: any = { theme_slug: this.theme, language: this.savedLng };
		return this.apiServ.callApiWithQueryParams('GET', 'SitePopups', queryParams).pipe(takeUntil(this.destroy)).toPromise().then((resp: any) => { this.themePopupApiResp(resp) });
	}

	/**
	 * Processes the response from the theme popup API and updates the theme popups data.
	 * Steps:
	 * 1. Validates the API response using `checkAPIRes` and ensures that the `data` array has a length greater than 0.
	 * 2. Iterates over the response data (`resData`) and organizes the popups by their `_id` key.
	 * 3. Populates the `_themePopups` object with the popups, using each popup's `_id` as the key.
	 * 4. Updates the `apiLoadStatus['themePopups']` flag to indicate that the theme popups have been successfully loaded.
	 * @param {any} resp - The API response containing theme popup data.
	 * @returns {void}
	 */
	private themePopupApiResp(resp: any): void {
		if (this.apiServ.checkAPIRes(resp) && (resp?.data)?.length > 0) {
			let resData: any = resp.data;
			let popups: any = {};
			// for(let popup of resData){
			for (let i in resData) {
				let popup = resData[i];
				popups[popup?._id] = popup;
			}
			this._themePopups = popups;
		}
		this.apiLoadStatus['themePopups'] = true;
	}

	/**
	 * App header footer
	 * @returns
	 */
	public async loadHeaderFooter(): Promise<any> {
		// await this.appLngCode();
		this._headerFooter = null;
		let queryParams: any = { theme_slug: this.theme, language: this.savedLng, mode: 'live' };
		if (this.theme) {
			queryParams['mode'] = 'preview';
		}
		return this.apiServ.callApiWithQueryParams('GET', 'HeaderFooter', queryParams).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => {
			if(this.apiServ.checkAPIRes(res) && res?.data){
				this._headerFooter = res.data;
				this.setLogoData.next(true);
			}
		});
	}

	/**
	 * App all booking spots
	 * @returns
	 */
	public bookingSpots(): Observable<any> {
		this._appBookingSpots = null;
		return this.apiServ.callApi('GET', 'AllBookingSpots').pipe(takeUntil(this.destroy)).toPromise().then((res: any) => {
			if(this.apiServ.checkAPIRes(res) && res?.data){
				this._appBookingSpots = res.data;
			}
		});
	}

	/**
	 * IP address for app
	 * @returns
	 */
	public appIpAddress(): any {
		this._ipAddress = null;
		return this.apiServ.callApi('GET', 'IpInfo', null, { auth: 'false' }, true).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this._ipAddress = res?.['ip']; });
	}

	/**
	 * App social keys
	 * @returns
	 */
	public loadSocialKeys(): Observable<any> {
		return this.apiServ.callApi('GET', 'Addons').pipe(takeUntil(this.destroy)).toPromise().then((resp: any) => { this.addonsApiResp(resp) });
	}

	/**
	 * Processes the response from the addons API and updates the social keys.
	 * Steps:
	 * 1. Resets `_socialKeys` to null at the start.
	 * 2. Validates the API response using `checkAPIRes` and ensures the `data` is an array with length greater than 0.
	 * 3. Iterates through the list of addons from the response:
	 *		 a. Initializes `_socialKeys` as an empty object if it is null.
	 *		 b. For each addon, checks its type (`facebook` or `google`):
	 *				 - If the addon is of type `facebook` and has a status of `1`, it adds the `app_id` to `_socialKeys`.
	 *				 - If the addon is of type `google` and has a status of `1`, it adds the `client_id` to `_socialKeys`.
	 * 4. This method ensures that only active (status `1`) social keys are stored.
	 * @param {any} resp - The API response containing the addons data.
	 * @returns {void}
	 */
	private addonsApiResp(resp: any): void {
		this._socialKeys = null;
		// App social keys
		if (this.apiServ.checkAPIRes(resp) && (resp?.data)?.length > 0) {
			let addons: any = resp.data;
			for (let addon of addons) {
				if (this._socialKeys == null) {
					this._socialKeys = {}
				}
				this.setSocialKeys(addon);
			}
		}
	}

	private setSocialKeys(addon: any){
		if (addon.addon_type == 'facebook') {
			if (this.checkAddonStatus(addon) && (addon.details?.app_id)) {
				this._socialKeys['Facebook'] = addon.details.app_id;
			}
		} else if (addon.addon_type == 'google' && this.isGoogleClientId(addon)) {
			this._socialKeys['Google'] = addon.details.client_id;
		}
	}

	private checkAddonStatus(addon: any){
		return (addon?.status && addon.status == 1);
	}

	private isGoogleClientId(addon: any){
		return (this.checkAddonStatus(addon) && (addon.details?.client_id))
	}

	/**
	 * App loggedIn user all information
	 * @param id User Id
	 * @returns
	 */
	public loggedInUser(id: any): Observable<any> {
		this._userInfo = null;
		return this.apiServ.callApiWithPathVariables('GET', 'Customer', [id]).pipe(takeUntil(this.destroy)).toPromise().then((resp: any) => { this.loggedInUserApiResp(resp) });
	}

	/**
	 * Processes the response from the logged-in user API and updates local user information.
	 * Steps:
	 * 1. Validates the API response using `checkAPIRes` and checks if the `data` object exists.
	 * 2. Updates the `_userInfo` property with the user data from the API response.
	 * 3. Retrieves the current user information from local storage.
	 * 4. If the current user exists, updates their `first_name`, `last_name`, and `photo_url` fields
	 *		 with values from the API response (if available).
	 * 5. Saves the updated user information back to local storage.
	 * 6. Emits a signal (`isUserProfile.next(true)`) to indicate that the user profile is available.
	 * 7. If the current user exists, fetches the referral balance using the user's `_id` from the API response.
	 *
	 * @param {any} resp - The API response containing the logged-in user's information.
	 * @returns {void}
	 */
	private loggedInUserApiResp(resp: any): void {
		if (!(this.apiServ.checkAPIRes(resp) && resp?.data)) {
			return;
		}
		this._userInfo = resp.data;
		let localItem: any = localStorage.getItem('currentUser');
		let currentUser: any = JSON.parse(localItem);
		if (currentUser && this._userInfo) {
			this.overriderCustomerLocalStorage(currentUser);
		}
		this.isUserProfile.next(true);
		if (currentUser) {
			// Referral balance
			this.userReferralsBal(resp.data._id);
		}
	}

	private overriderCustomerLocalStorage(currentUser: any){
		// Set the user local storage
		currentUser['first_name'] = this._userInfo?.first_name ? this._userInfo.first_name : '';
		currentUser['last_name'] = this._userInfo?.last_name ? this._userInfo.last_name : '';
		currentUser['photo_url'] = this._userInfo?.photo_url ? this._userInfo.photo_url : '';
		try {
			localStorage.setItem('currentUser', JSON.stringify(currentUser));
			// eslint-disable-next-line no-empty
		} catch (err) { }
	}

	/**
	 * User referral balance
	 * @param id: user id
	 * @returns
	 */
	public userReferralsBal(id: any): Observable<any> {
		// this._userRefBal = null;
		return this.apiServ.callApiWithPathQueryVars('GET', 'ReferralsBalance', [id]).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => {
			if (this.apiServ.checkAPIRes(res) && res?.data) {
				this.userRefBal.next(res.data);
			}
		});
	}

	/**
	 * Change app routing
	 */
	private changeAppRouting(): void {
		let routerConfig: any = this.router.config;
		routerConfig[24].children = this.replaceRoute(routerConfig[24].children);
		routerConfig[25].children = this.replaceRoute(routerConfig[25].children);
		routerConfig = this.replaceRoute(routerConfig);
		routerConfig = this.generateNewRoutes(routerConfig);
		this.router.resetConfig(routerConfig);
	}

	/**
	 * Replace the route
	 * @param children: Old child
	 * @returns children's
	 */
	private replaceRoute(children: any): void {
		if(children && children.length > 0){
			for(let i in children){
				let child = children[i];
				if(this.appDynamicRoutes[child.path]){
					child.path = this.appDynamicRoutes[child.path];
				}
			}
		}
		return children;
	}

	/**
	 * Add embed form routes in the system if there URL is updated
	 * @param routerConfig
	 * @returns Updated routerConfig with new routes.
	 */
	private generateNewRoutes(routerConfig: any): any {
		if(this.appDynamicRoutes.booknow != 'booknow'){
			let booknowRoute: any = { path: 'booknow', loadChildren: () => import('../AddBookings/AddBookings.module').then(m => m.AddBookingsModule)}
			routerConfig = this.pushBooknowAndContactUsRoutes(routerConfig, 24, booknowRoute);
		}
		if(this.appDynamicRoutes['contact-us'] != 'contact-us'){
			let leadFormRoute: any = { path: 'contact-us', loadChildren: () => import('../LeadForm/LeadForm.module').then(m => m.LeadFormModule), runGuardsAndResolvers: 'always'}
			routerConfig = this.pushBooknowAndContactUsRoutes(routerConfig, 25, leadFormRoute);
		}
		return routerConfig;
	}

	private isActiveRouterConfig(routerConfig: any, routerNo: number) {
		return routerConfig?.[routerNo]?.children;
	}

	private pushBooknowAndContactUsRoutes(routerConfig: any, routerNo: number, pushedRoute: any){
		if(this.isActiveRouterConfig(routerConfig, routerNo)){
			routerConfig[routerNo].children.push(pushedRoute);
		}
		return routerConfig;
	}

	/**
	 * Admin settings
	 * @returns Api Res
	 */
	public admnStngs(): any {
		this._appAdmnStngs = null;
		return this.apiServ.callApi('GET', 'AdmnStngs').pipe(takeUntil(this.destroy)).toPromise().then((res: any) => {
			if (this.apiServ.checkAPIRes(res) && res?.data) {
				this._appAdmnStngs = res.data;
				this.storeAdminSettsGlobalVar();
			}
		});
	}

	/**
	 * Set the admin setting global variables values
	 * Phone number masking, language code, service fee label and calling code
	 */
	private storeAdminSettsGlobalVar(): void {
		if (!this._appAdmnStngs?.merchant_settings) {
			return;
		}
		// Set the 3DS global variable
		this.setThreeDSecure();
		// Set store date format
		this.setDateFormat();
		// Active theme
		if (this.theme) {
			this.activeTheme = this.theme;
		} else if (this._appAdmnStngs?.active_theme) {
			this.activeTheme = this._appAdmnStngs.active_theme;
		}
		// Set the app language cookie
		this.setAppLang();
		this.setRatingAllowedStatus();
		// Payment gateway
		this.setPaymentGatewayMethod();
		this.setPhoneNoMasking();
		this.setPhoneNumber();
		// Service fee label
		this.setServiceFee();
		// Set the custom tax
		this.setCustomTaxLabel();
		// App custom routes
		this.getAppRoutes();
	}

	private setPaymentGatewayMethod(): void {
		if (!this._appAdmnStngs.merchant_settings?.payment_method) {
			return;
		}
		let isSquare = this._appAdmnStngs.merchant_settings.payment_method.enable_square;
		let isPaypal = this._appAdmnStngs.merchant_settings.payment_method.enable_paypal_braintree;
		let isAuthDotNet = this._appAdmnStngs.merchant_settings.payment_method.enable_authorizedotnet;
		this.paymentGatewayMethod(isSquare, isPaypal, isAuthDotNet);
		// No payment method enable, default stripe enable
		if (this.isNotSquareAndAuthDotNet(isSquare, isPaypal) && this.isNotOtherPaymentMethod(isAuthDotNet)) {
			this.paymentGateway = 'stripe';
		}
		this.setBillingAddr();
	}

	private isNotOtherPaymentMethod(paymentMethod: any): boolean {
		return (!paymentMethod || paymentMethod == 'no');
	}

	private isNotSquareAndAuthDotNet(isSquare: any, isPaypal: any){
		return this.isNotOtherPaymentMethod(isSquare) && this.isNotOtherPaymentMethod(isPaypal);
	}

	private paymentGatewayMethod(isSquare: any, isPaypal: any, isAuthDotNet: any){
		if (isSquare == 'yes') {
			this.paymentGateway = 'square';
		} else if (isPaypal == 'yes') {
			this.paymentGateway = 'paypal';
		} else if (isAuthDotNet) {
			this.paymentGateway = 'authorizedotnet';
		} else {
			this.paymentGateway = 'stripe';
		}
	}

	private setBillingAddr(): void {
		this.allowBillingAddr = this.paymentGateway == 'authorizedotnet' ? true : this._appAdmnStngs.merchant_settings?.payment_method?.add_billing_address == 'yes';
	}

	private setPhoneNoMasking(){
		if (this._appAdmnStngs.merchant_settings?.store) {
			// eslint-disable-next-line no-prototype-builtins
			if (this._appAdmnStngs.merchant_settings.store.phone_number_format && MASKS_OBJ.hasOwnProperty(this._appAdmnStngs.merchant_settings.store.phone_number_format)) {
				this.selectedMask = MASKS_OBJ[this._appAdmnStngs.merchant_settings.store.phone_number_format];
			}
		}
	}

	private setPhoneNumber(): void {
		if (this._appAdmnStngs.merchant_settings?.store) {
			this.callingCode = '';
			if (this.isShowCallingCode() && this.isCallingCode()) {
				this.callingCode = this._appAdmnStngs.merchant_settings.store.country_calling_code;
			}
		}
	}

	private isShowCallingCode(): boolean {
		// eslint-disable-next-line no-prototype-builtins
		return ((this._appAdmnStngs.merchant_settings.store).hasOwnProperty('show_calling_code') && this._appAdmnStngs.merchant_settings.store.show_calling_code && this._appAdmnStngs.merchant_settings.store.show_calling_code == 'yes');
	}

	private isCallingCode(): boolean {
		// eslint-disable-next-line no-prototype-builtins
		return ((this._appAdmnStngs.merchant_settings.store).hasOwnProperty('country_calling_code') && this._appAdmnStngs.merchant_settings.store.country_calling_code);
	}

	private setServiceFee(){
		if (this._appAdmnStngs.merchant_settings?.general?.customize_service_fee_label && this._appAdmnStngs.merchant_settings.general.customize_service_fee_label == 'yes') {
			this.serviceFeeLabel = this._appAdmnStngs.merchant_settings.general.service_fee_label;
		}
	}

	/**
	 * Sets the custom tax label for a store, defaulting to 'Sales tax' if not provided.
	 */
	private setCustomTaxLabel(): void {
		this.customTaxLabel = this._appAdmnStngs?.merchant_settings?.store?.custom_tax_label ?? 'Sales tax';
	}

	private setRatingAllowedStatus(): void {
		if(this._appAdmnStngs.merchant_settings?.customers){
			if(this.isAllowCustRating()){
				this.isRatingAllowed = false;
			} else if((this._appAdmnStngs.merchant_settings.customers?.allow_customer_rating_for)?.length > 0){
				this.ratingAllowedFor = this._appAdmnStngs.merchant_settings.customers.allow_customer_rating_for;
			}
		}
	}
	private isAllowCustRating(): boolean {
		return this._appAdmnStngs.merchant_settings.customers?.allow_customer_rating && this._appAdmnStngs.merchant_settings.customers.allow_customer_rating == 'no';
	}
	/**
	 * Set the three D Secure global variable
	 */
	private setThreeDSecure(): void {
		if (this._appAdmnStngs?.merchant_settings?.payment_method?.enable_3ds && this._appAdmnStngs.merchant_settings.payment_method.enable_3ds == 'yes') {
			this.threeDSecure = true;
		}
	}

	/**
	 * Set the app language and cookie
	 */
	private async setAppLang() {
		this.setDefaultLang();
		let langPlanPerm = await this.isLangPlan();
		if (langPlanPerm.isPlanPermission || langPlanPerm.isAllowOneLang) {
			if (langPlanPerm.isAllowOneLang) {
				this.savedLng = this.defaultProvLang;
				this.cookieServ.createCookie(this.lngCookieName, '', 365);
				this.setProvLangStatus = true;
			} else{
				this.setCustSavedLang();
			}
		}
		this.isLangSet.next(true);
		this.loadCssFiles(this.savedLng ? this.savedLng : 'en');
	}

	private setDefaultLang(): void {
		if (this._appAdmnStngs.merchant_settings?.language) {
			if(this._appAdmnStngs.merchant_settings.language?.customer_default_lang){
				this.defaultLangCode = this._appAdmnStngs.merchant_settings.language.customer_default_lang;
			}
			// Default provider language
			if (this._appAdmnStngs.merchant_settings.language?.provider_default_lang) {
				this.defaultProvLang = this._appAdmnStngs.merchant_settings.language.provider_default_lang;
			}
		}
	}

	private async isLangPlan(){
		let isPlanPermission: boolean = true;
		let isAllowOneLang: boolean = false;
		if (this._appAdmnStngs?.package_id) {
			isPlanPermission = await this.appPlansPermission('translation');
			if (!isPlanPermission) { isAllowOneLang = true; }
		}

		return {isPlanPermission, isAllowOneLang}
	}

	private setCustSavedLang(): void {
		let savedLng = this.cookieServ.getCookie(this.lngCookieName);
		if(savedLng){
			this.savedLng = savedLng;
		} else {
			this.savedLng = this.defaultLangCode;
			this.cookieServ.createCookie(this.lngCookieName, this.savedLng, 365);
		}
	}

	/**
	* Check industry status for customer.
	* Check "customer_status" value
	*/
	private indusStatusForCust(industry: any): boolean {
		if (industry?.customer_status && industry.customer_status == 2) {
			return false;
		}
		return true;
	}

	/**
	* Check the location type is no_location, name and zipcode
	* If any one location name and zipcode base under the industries and forms.
	* Set the locationsStatus variable val;
	*/
	public checkLocationsStatus() {
		if (this._appData?.industries && (this._appData.industries).length > 0) {
			for (let i in this._appData.industries) {
				let industry = this._appData.industries[i];
				if (this.isIndustryActive(industry) && (industry?.forms)?.length > 0) {
					let locStatus = (industry.forms).some((form: { form_status: any; location_type: string; }) => {
						if (form.form_status && form.location_type != 'no_location') {
							return true;
						}
						return false;
					});
					if (locStatus) {
						this.locationsStatus = true;
						break;
					} else {
						this.locationsStatus = false;
					}
				}
			}
		}
	}

	private isIndustryActive(industry: any): boolean {
		return (industry.status == 1 && this.indusStatusForCust(industry));
	}

	/**
	 * Append the new slug based urls for the inner pages that is added into the appDynamic routes object.
	 * @param userRoutes slug based urls for the inner pages
	 * @returns
	 */
	private appendUserRoutes(userRoutes: any): Promise<any>{
		let obj:any = {}
		return new Promise<any>((resolve) => {
			if(Object.keys(userRoutes)?.length > 0){
				for(let route in userRoutes){
					obj[userRoutes[route]] = this.appDynamicRoutes[route]
				}
			}
			resolve(obj);
		});
	}

	/**
	 * Add the user pages (inner pages) routes when appDynamicRoutes does not contains in it.
	 */
	private addUserPages(): void {
		if (Object.keys(this.defaultUserPagesRoutes)?.length > 0) {
			for (let route in this.defaultUserPagesRoutes) {
				// if routes does not contain in appDynamicRoutes then add the route in it.
				if (!this.appDynamicRoutes[route]) {
					this.appDynamicRoutes[route] = this.defaultUserPagesRoutes[route];
				}
			}
		}
	}

	/**
	 * Get the customization text
	 * @param id: section id
	 * @param settings: settings
	 * @param content: content
	 * @returns
	 */
	private getText(id: string, settings: any, content: any): string {
		if (settings?.[id] && settings[id][this.statusType]) {
			if (content[id]) {
				return content[id];
			}
			return '';
		}
		return 'ele_hide';
	}
	/**
	 * Get the app custom routes array
	 */
	private getAppRoutes(): void {
		const topLevelRoutes = this.router.config.slice(0, this.router.config.findIndex((route) => route.path === '**') ?? this.router.config.length - 1);
		if (topLevelRoutes && topLevelRoutes.length > 0) {
			for (const i of topLevelRoutes) {
				this.getPaths(i);
			}
		}
	}
	/**
	 * Get the app custom path like /login,/dashboard etc
	 * @param route Route
	 * @param parent parent route
	 * @returns
	 */
	private getPaths(route: Route, parent: string = ''): void {
		if (route.redirectTo) {
			return;
		}
		if (route.children && (route.children).length > 0) {
			route.children.forEach(i => {
				this.getPaths(i, parent + route.path);
			});
		} else if (route.loadChildren) {
			(<any>this.router).configLoader.load(this.injector, route).subscribe((i: any) => {
				i.routes.forEach((j:any) => {
					this.getPaths(j, parent + route.path)
				});
			});
		} else if (route.path != null) {
			if (route.path !== '') {
				this.appRoutes.push(parent ? `/${parent}/${route.path}` : `/${route.path}`);
			} else if(parent){
				this.appRoutes.push(`/${parent}`);
			}
		}
	}
	private getFlag(langCode: string):string {
		if(this.appLanguages && (this.appLanguages).length > 0){
			for(let lang of this.appLanguages){
				if(lang.code == langCode){
					return lang.flag;
				}
			}
		}
		return `${langCode}.png`
	}
	/**
	 * Load a css file based on language
	 * @param langCode
	 */
	private loadCssFiles(langCode: string): void {
		let elem: any = document.getElementsByClassName('custom-translation-style');
		while (elem.length > 0) {
			elem[0].parentNode.removeChild(elem[0]);
		}
		if (!langCode) {
			return;
		}
		let rtlLangs: Array<string> = ['ar', 'ha', 'he', 'ku', 'fa', 'ur'];
		this.selectedLang = {
			code: langCode,
			rtl: rtlLangs.includes(langCode),
			flag: this.getFlag(langCode)
		};
		// Add.remove class on body tag.
		let htmlElem: any = document.getElementsByTagName("html");
		if (this.selectedLang.rtl || IS_DEV) {
			this.addRtlCss(htmlElem);
		}
		this.removeRtlClass(htmlElem);
		this.setTranslation.next(true);
	}

	private addRtlCss(elem: any): void {
		this.loader.show()
		this.isRTLChange.next(this.selectedLang.rtl);
		let path = this.selectedLang.rtl ? '/rtl/' : '/';
		let themePath: any = IS_DEV ? `/assets/css${path}${this.activeTheme}` : `${this.APIURL.bkcdn}/assets/css/${this.APIURL.themeVersion}${path}${this.activeTheme}`;
		let rendNo = Math.floor(Math.random() * 1000000000);
		let link = document.createElement('link');
		link.id = 'theme-css';
		link.rel = 'stylesheet';
		link.type = 'text/css';
		link.href = `${themePath}-theme.css?v=${rendNo}`;
		let head = document.head || document.getElementsByTagName('head')[0];
		let existingStyleSheet: any = document.getElementById('theme-css');
		if (existingStyleSheet) {
			existingStyleSheet.parentNode.insertBefore(link, existingStyleSheet.nextSibling);
			existingStyleSheet.remove();
		} else {
			head.prepend(link);
		}
		document.body.classList.add('tjs-rtl-layout');
		if (elem && elem.length > 0) {
			elem[0].setAttribute('dir', 'rtl');
		}
		this.loader.hide()
	}

	private removeRtlClass(htmlElem: any): void {
		if (!this.selectedLang.rtl && document.body.classList.contains('tjs-rtl-layout')) {
			document.body.classList.remove('tjs-rtl-layout');
			if (htmlElem && htmlElem.length > 0) {
				htmlElem[0].removeAttribute('dir');
			}
		}
	}

	/**
	 * Check the app plan permission (PREMIUM_PLANS)
	 * @param name: section name: ['multi-step-form', 'translation', 'discount-bar', 'checklist_notes']
	 * @returns boolean
	 */
	public appPlansPermission(name: string): boolean {
		let planId = (this._appAdmnStngs?.package_id) ? this._appAdmnStngs.package_id : '';
		if (planId && this.isPremiumPlans(name, planId)) {
			return true;
		}
		return false;
	}

	private isPremiumPlans(name: string, planId: string): boolean {
		return ADV_FEATURES.includes(name) && PREMIUM_PLANS.includes(planId)
	}
	/**
	 * Check the app growing plan permissions (GROWING_PLANS)
	 * @param name: section name: ['checklist_notes']
	 * @returns boolean
	 */
	public appGrowingPlansPerm(name: string): boolean {
		let planId = (this._appAdmnStngs?.package_id) ? this._appAdmnStngs.package_id : '';
		if (planId && this.isGrowingPlans(name, planId)) {
			return true;
		}
		return false;
	}

	private isGrowingPlans(name: string, planId: string): boolean {
		return (GROWING_PLANS.includes(planId) && GROWING_FEATURES.includes(name));
	}

	/**
	 * Get trial data and start trial if user is not on premium plans
	 */
	public isStarterPlans(name: string): boolean {
		let status: boolean = this.appPlansPermission(name);
		if(!status){
			status = this.appGrowingPlansPerm(name)
		}
		return !status;
	}
	/**
	 * Get the auth header(auth_token and timestamp)
	 * @returns object
	 */
	public async getAuthHeader(): Promise<any> {
		let domainName: any = this._appAdmnStngs?.merchant_settings?.store?.domain_name;
		let date = new Date().getTime();
		let dateString = date ? date.toString() : '';
		let authKey: any = domainName + AUTH_KEY + dateString;
		let authKey1 = await sha512Hash(authKey);
		let authKey2 = await sha512Hash(domainName);
		let authToken: any = `${authKey1}_${authKey2}`;
		return { 'Auth-token': authToken, 'Timestamp': dateString };
	}

	public setCurrPageIdInBody(pageId: any): void {
		if(!pageId){
			return;
		}
		let bodyElement: any = document.body;
		bodyElement.setAttribute('data-curr_page_id', pageId.toString());
		if(this.appPlansPermission('leads')){
			this.autoLoadLeadsPopup();
		} else {
			this.apiServ.callApiWithQueryParams("GET", 'FeaturesTrial', {name: 'leads'}).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.checkFeaturesTrial(res));
		}
	}

	/**
	 * Checks the trial status of features based on the API response.
	 * @param {any} res - Response object from the API call
	 */
	private checkFeaturesTrial(res: any): void {
		if(this.apiServ.checkAPIRes(res, false) && this.isTrailForLead(res?.data)){
			// Automatically load leads popup if conditions are met
			this.autoLoadLeadsPopup();
		}
	}

	/**
	 * Checks if the 'leads' feature is present in the provided data.
	 * @param {Array} data - The data containing feature objects.
	 * @returns {boolean} - Returns true if the 'leads' feature is found, otherwise false.
	 */
	private isTrailForLead(data: any[]): boolean {
		// Find the feature object with name 'leads' in the data
		const isStarted = data.find((feature) => feature?.name === 'leads');

		// If the 'leads' feature is found, return true; otherwise, return false
		return !!isStarted;
	}
	private autoLoadLeadsPopup(): void {
		let leadsScript: any = document.getElementById('auto-load-leads-popup-script');
		if(!leadsScript){
			let scriptElem: any = document.createElement('script');
			// scriptElem.src = this.initServ.baseUrl+'/resources/auto-load-leads-popup.min.js';
			scriptElem.src = this.baseUrl+'/resources/auto-load-leads-popup.js';
			scriptElem.id = 'auto-load-leads-popup-script';
			scriptElem.async = true;
			document.head.appendChild(scriptElem);
		}
		setTimeout(() => {
			// if(!IS_DEV){
				(window as any).fetchLeadPopups();
			// }
		}, 1000);
	}

	private setDateFormat(): void{
		if(this._appAdmnStngs?.merchant_settings?.store?.date_format){
			this.storeDateFormat = this._appAdmnStngs.merchant_settings.store.date_format;
		}
	}

	/**
	 * Generates the necessary headers for a session authentication request.
	 */
	public async getSessionHeaders(domainName: string = '') {
		// Global variable for !temporarily commented from here to handle security failure callback. When the new security is not stable on production then it will be enabled. We remove this when every think working fine.
		if(ALLOW_OLD_VERSION){
			return await getSessionHeaders(env.arjun+env.bhishma+env.chanakya+env.drona, env.astonmartin+env.bentley);
		} else {
			let params : {domainName: string, timeDiff: number, appName: string} = {
				domainName: domainName ? domainName : this.getDomainName(),
				timeDiff: this.timestampTimeDiff,
				appName: 'customer-32'
			}
			return await generateSessionHeaders(env.audi+env.buick+env.chevrolet+env.dodge, env.abhimanyu+env.bharata+env.chandra+env.devika, params);
		}
	}

	/**
	 * Gets the domain name based on the environment base URL.
	 * If the base URL contains 'bookingkoala.com' or 'bookingkoala.co.in', returns the domain name from environment variables.
	 * Otherwise, returns the domain name from the current window location.
	 * @returns The domain name as a string.
	 */
	public getDomainName(): string {
		let hostUrl: string = env.baseUrl;
		if(hostUrl.includes('bookingkoala.com') || hostUrl.includes('bookingkoala.co.in')){
			return env.domainName;
		}
		return window.location.host;
	}

	/**
	 * Method makes a request to the 'DesignSett' API to get the global design settings object in the response.
	 */
	public applyGlobalStyleApi() {
		return this.apiServ.callApi('GET', 'DesignSett').pipe(takeUntil(this.destroy)).toPromise().then((resp: any) => this.applyGlobalStyleApiResp(resp));
	}

	/**
	 * Response handler for `applyGlobalStyleApi` method which applies custom font settings, colors, and CSS styles to a web.
	 * page based on data received from an API response.
	 * @param {any} res - The `res` parameter object that contains data related to design settings ie, font settings, color settings, and custom CSS for styling elements on a web page.
	 * The function processes this data to apply global styles to the page based on
	 */
	// eslint-disable-next-line max-lines-per-function, complexity
	private applyGlobalStyleApiResp(res:any):void {
		if (this.apiServ.checkAPIRes(res) && res?.data) {
			let data = res.data;
			if (!data?.design_settings) { return; }
			let style: string = "";
			// destructed fields from object design settings
			let {font_settings, color_settings, custom_css} = data.design_settings;
			// code to apply font size and font family
			if (font_settings?.enable_custom_font) {
				// self calling block for loading dynamic web fonts link
				(() => {
					const doc: Document = document;
					let wf = doc.createElement('script'), s = doc.scripts[0];
					wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
					wf.async = true;
					wf.onload = () => {
						(window as any).WebFont.load({
							google: {
								families: [`${font_settings.base_font_type}:300,400,700,900&display=swap`]
							}
						});
					}
					s.parentNode?.insertBefore(wf, s);
				})();
				//  applying type font customization
				if (font_settings.base_font_type) {
					style += `html,#bkIframe{font-family:${font_settings.base_font_type};}`;
				}
				if (font_settings.size) {
					style += `@media (min-width: 992px) {html,#bkIframe {font-size:${font_settings.size}px;}}`;
				}
				if (font_settings.mob_size) {
					style += `@media (max-width: 991px) {html,#bkIframe {font-size:${font_settings.mob_size}px;}}`;
				}
			}
			// code to apply font colors
			if (color_settings?.enable_custom_colors) {
				if (color_settings?.text_color) {
					style += `#bkIframe{color:${color_settings.text_color};}`;
				}
				if (color_settings?.link_color) {
					style += `a{color:${color_settings.link_color};}`;
				}
				if (color_settings?.active_link_color) {
					style += `a:active,a:hover{color:${color_settings.active_link_color};}`;
				}
				if (color_settings?.heading_color) {
					style += `h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{ color:${color_settings.heading_color};}`;
				}
				// Radio/checkbox
				if (color_settings?.radio_color) {
					style += `.form-check-input:checked{border-color:${color_settings.radio_color};background-color:${color_settings.radio_color};}`
				}
				// Calendar
				let cal_active_bg_color = (color_settings?.cal_active_bg_color) ? color_settings.cal_active_bg_color : '';
				let cal_active_color = (color_settings?.cal_active_color) ? color_settings.cal_active_color : '';
				if (cal_active_bg_color || cal_active_color) {
					style += `.tjs-bcalendar__active:not(.tjs-bcalendar__past-date){background:${cal_active_bg_color};color:${cal_active_color};}`;
					style += `.tjs-bcalendar__day:hover:not(.tjs-bcalendar__past-date){background:${cal_active_bg_color};color:${cal_active_color};}`;
					style += `.tjs-bcalendar__today:not(.tjs-bcalendar__active):hover{background:${cal_active_bg_color};color:${cal_active_color};}`;
				}
				// New Style for loader
				if (color_settings?.loader_color) {
					style += `.tjs-loader--v2 .loader .dot{ background : ${color_settings.loader_color};}`;
				}
			}
			// custom css
			if (custom_css?.css) {
				style += custom_css.css;
			}
			let existingStyleSheet = document.getElementById('global-style');
			if (existingStyleSheet) {
				existingStyleSheet.parentNode?.removeChild(existingStyleSheet);
			}
			// create style tag
			let styleTag: HTMLStyleElement = document.createElement('style');
			document.body.appendChild(styleTag);
			styleTag.type = 'text/css';
			styleTag.id = 'global-style';
			styleTag.appendChild(document.createTextNode(style));
		}
	}

	// /**
	//  * Retrieves the CSRF token by making a GET request to the 'Preload' API.
	//  * @returns {Observable<any>} The API call for the CSRF token.
	//  */
	// public getCsrfToken(): Observable<any>{
	// 	return this.apiServ.callApi('GET', 'Preload');
	// }

	// /**
	//  * Validates the API response for the CSRF token and updates the local CSRF token.
	//  * If the response is valid and contains a CSRF token, it stores the token
	//  * and initiates the sliding window mechanism for token expiration management.
	//  * @param resp - The response object from the CSRF token API call.
	//  */
	// private csrfTokenApiResp(resp: csrfTokenApiResp): void {
	// 	if (this.apiServ.checkAPIRes(resp) && resp?.data?.['X-CSRF-Token']) {
	// 		this._csrfToken = resp.data['X-CSRF-Token'];
	// 		this.startSlidingWindow();
	// 	}
	// }

	// /**
	//  * Starts the sliding window mechanism for refreshing the CSRF token.
	//  * Steps:
	//  * 1. Sets a sliding window for the CSRF token with a 30-minute duration.
	//  * 2. Schedules a token refresh 5 minutes before expiration.
	//  * 3. After the refresh, the sliding window restarts.
	//  * The `getCsrfToken()` method is called to retrieve the token, and if the response is valid and contains the CSRF token,
	//  * the sliding window is restarted by recursively calling `startSlidingWindow()`.
	//  */
	// private startSlidingWindow(): void {
	// 	// Set sliding window to 30 minutes, refresh token 5 minute before expiration
	// 	const expirationTime = 5 * 60 * 1000; // 30 minutes
	// 	const refreshTime = expirationTime - (4 * 60 * 1000); // 5 minute before expiration
	// 	timer(refreshTime).subscribe(async () => {
	// 		try {
	// 			let resp: csrfTokenApiResp = await firstValueFrom(this.getCsrfToken().pipe(takeUntil(this.destroy)));
	// 			this.csrfTokenApiResp(resp);
	// 		} catch (error) {
	// 			if(IS_DEV){
	// 				console.log('Error fetching preload Token:', error);
	// 			}
	// 		}
	// 	});
	// }

	// /**
	//  * Initializes the application data by first fetching the CSRF token and then
	//  * calling several other APIs in parallel to load necessary data for the app.
	//  * Steps:
	//  * 1. Fetches the CSRF token using `getCsrfToken()`.
	//  * 2. If the CSRF token is successfully retrieved, starts the sliding window for token refreshing.
	//  * 3. Once the CSRF token is fetched, several APIs are called in parallel using `forkJoin` to load:
	//  *	- Application load data
	//  *	- Admin settings
	//  *	- Languages
	//  *	- Page data
	//  *	- Site data
	//  *	- Booking spots
	//  *	- Social keys
	//  *	- Global styles
	//  * @returns {Observable<any>} An observable that resolves once all APIs have returned their data.
	//  */
	// public initializeAppData(): Observable<any> {
	// 	this._csrfToken = '';
	// 	return this.getCsrfToken().pipe(
	// 		tap((resp: csrfTokenApiResp) => {
	// 			this.csrfTokenApiResp(resp);
	// 		}),
	// 		switchMap(() => {
	// 			// Once CSRF token is fetched, Call other APIs in parallel using an object with named properties
	// 			return forkJoin({
	// 				appLoad: this.appload(),
	// 				adminSettings: this.admnStngs(),
	// 				languages: this.loadLanguages(),
	// 				pageData: this.pageData(),
	// 				appSiteData: this.appSiteData(),
	// 				bookingSpots: this.bookingSpots(),
	// 				socialKeys: this.loadSocialKeys(),
	// 				globalStyles: this.applyGlobalStyleApi()
	// 			});
	// 		}),
	// 		takeUntil(this.destroy)
	// 	);
	// }
	/**
	 * Booking customization data, handle by theme builder
	 * @returns Api Res
	 */
	public async loadServCatsAndFreqs(): Promise<any> {
		return this.apiLoadStatus['servsAndFreqs'] ? Promise.resolve() : this.apiServ.callApi('GET', 'ServsAndFreqs').pipe(takeUntil(this.destroy)).toPromise().then((resp: any) => { this.servsAndFreqsApiResp(resp) });
	}
	private servsAndFreqsApiResp(resp: any): any {
		if(this.apiServ.checkAPIRes(resp) && resp?.data) {
			this.allServCats = resp.data.service_category;
			this.allFreqs = resp.data.form_frequencies;
		}
		this.apiLoadStatus['servsAndFreqs'] = true;
	}

	private getServerTime() {
		return this.apiServ.callApi('GET', 'ServerUTCTime', null, { auth: 'false' }, true);
	}

	/**
	 * This function initializes the application data by performing the following steps:
	 * 1. Resets the local time difference (`_localTimeDiff`) to 0.
	 * 2. Calls the API to get the server's current UTC time.
	 * 3. If the API response is valid and contains the server's timestamp (`unix_time`), it calculates the time difference between the server time and the client's local time.
	 *		- The current local time is obtained in UTC using an ISO string, and then converted to a Unix timestamp (in seconds).
	 *		- The time difference (`_localTimeDiff`) is the difference between the local timestamp and the server timestamp.
	 * 4. After the server time is retrieved and the local time difference is calculated, multiple API calls are made in parallel to fetch various app-related data:
	 *		- `appload()`: Loads app-specific data.
	 *		- `admnStngs()`: Retrieves admin settings.
	 *		- `loadLanguages()`: Loads available languages.
	 *		- `pageData()`: Retrieves content for the current page.
	 *		- `appSiteData()`: Fetches site-related app data.
	 *		- `bookingSpots()`: Fetches available booking spots.
	 *		- `loadSocialKeys()`: Retrieves social media API keys.
	 *		- `applyGlobalStyleApi()`: Applies global styles for the app.
	 * 5. The `takeUntil` operator is used to clean up and stop the execution when the component is destroyed (using `destroy` as the observable signal).
	 * @returns An Observable that emits the combined result of all parallel API calls.
	 */
	public initializeAppData(): Observable<any> {
		this._localTimeDiff = 0;
		return this.getServerTime().pipe(
			tap((resp: any) => {
				if (this.apiServ.checkAPIRes(resp?.response) && resp?.response?.data?.['unix_time']) {
					let serverTimestamp = resp.response.data['unix_time'];
					let custCurrentTimeStamp: number = this.getCustCurrentTimestamp();
					this._localTimeDiff = custCurrentTimeStamp-serverTimestamp;
				}
			}),
			switchMap(() => {
				// Once CSRF token is fetched, Call other APIs in parallel using an object with named properties
				return forkJoin({
					appLoad: this.appload(),
					adminSettings: this.admnStngs(),
					languages: this.loadLanguages(),
					pageData: this.pageData(),
					appSiteData: this.appSiteData(),
					bookingSpots: this.bookingSpots(),
					socialKeys: this.loadSocialKeys(),
					globalStyles: this.applyGlobalStyleApi()
				});
			}),
			takeUntil(this.destroy)
		);
	}

	private getCustCurrentTimestamp(): number{
		// Get the current date and time in ISO string format
		// Convert the ISO string to a UTC timestamp in seconds
		return Math.floor(new Date(new Date().toISOString()).getTime() / 1000);
	}
	/**
	 * Securely encrypts the provided string value using GCM mode.
	 * This function uses a combination of environment variables to form the encryption key.
	 * It returns the encrypted string in a Promise.
	 * @param {string} val - The string value to be encrypted.
	 * @returns {Promise<string>} - A Promise that resolves to the encrypted string.
	 * @throws Will not throw an error but will return undefined if the input value is empty.
	 */
	public async secureEncrypt(val: string): Promise<string> {
		if (!val) {
			return ''; // Early exit if the input is not provided
		}
		return await encryptGCM(val, env.satya + env.treta + env.dvaeara + env.kali);
	}

	/**
	* Securely decrypts the provided encrypted string value using GCM mode.
	* This function uses a combination of environment variables to form the decryption key.
	* It returns the decrypted string in a Promise.
	* @param {string} val - The encrypted string value to be decrypted.
	* @returns {Promise<string>} - A Promise that resolves to the decrypted string.
	* @throws Will not throw an error but will return undefined if the input value is empty.
	*/
	public async secureDecrypt(val: string): Promise<string> {
		if (!val) {
			return ''; // Early exit if the input is not provided
		}
		return await decryptGCM(val, env.satya + env.treta + env.dvaeara + env.kali);
	}
}
