/* eslint-disable max-depth */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Injectable, Self } from '@angular/core';
import { takeUntil, Observable } from 'rxjs';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
// Services
import { InitServ, UtilServ, NgOnDestroy, ApiServ } from './index';

declare const parentToTop: any;
@Injectable({
	providedIn: 'root'
})
export class SchedulingServ {

	// private appData: any;
	private admnStngs: any;
	private currentUser: any;
	enable_job_length : any = 'yes'
	globals : any = {};
	existingDates : any = []
	cancelledDates : any = []

	consistentFreq : any = {"daily":1,"every_week":7,"every_2_weeks":14,"every_3_weeks":21,"every_4_weeks":28,"every_5_weeks":35,"every_6_weeks":42,"every_7_weeks":49,"every_8_weeks":56,"every_9_weeks":63,"every_10_weeks":70,"every_11_weeks":77,"every_12_weeks":84,"every_24_weeks":168}
	inConsistentFreq : any = ["daily_no_saturday_sunday","daily_no_sunday","every_monday_friday","every_monday_wednesday_friday","every_tuesday_and_thursday","saturday_sunday","every_tuesday_wednesday_friday","every_monday_wednesday","every_monday_thursday"];

	constructor(private initServ: InitServ, public apiServ: ApiServ, public utilServ : UtilServ, @Self() private destroy: NgOnDestroy) {
		// this.appData = this.initServ.appData; // App data
		this.admnStngs = this.initServ.appAdmnStngs; // App admin settings
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// User logged in get again current user local storage
		this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if(value){
				this.currentUser = this.utilServ.appLocalStorage();
			}
		});

		this.adminSettingInitialize();
	}

	/* Function to build object of required settings to use directly in functions */
	adminSettingInitialize(){
	 	this.globals.jobBeginTime = this.admnStngs?.merchant_settings?.bookings?.job_begin_time;
	 	if (this.admnStngs?.merchant_settings && this.admnStngs.merchant_settings?.bookings) {
	 		this.globals.allowSameDayBooking = this.admnStngs.merchant_settings?.bookings?.allow_same_day_booking;
	 		this.globals.sameDayBookingTimeLimit = this.admnStngs.merchant_settings?.bookings?.same_day_booking_time_limit;
	 		this.globals.sameDayBookingLimitTimeValue = this.admnStngs.merchant_settings?.bookings?.same_day_booking_time_limit_value;
	 		this.globals.sameDayBookingDayLimitValue = this.admnStngs.merchant_settings?.bookings?.same_day_booking_day_limit_value;
			if(this.admnStngs.merchant_settings?.bookings?.booking_day_before){
				this.globals.day_before = this.admnStngs.merchant_settings?.bookings?.booking_day_before;
			}
	 	}

	 	this.globals.checkWHend = this.admnStngs?.merchant_settings?.bookings?.check_wh_end_time ? this.admnStngs.merchant_settings?.bookings?.check_wh_end_time  : 'yes'
	 	if(this.admnStngs?.merchant_settings?.bookings?.enable_job_length){
			this.globals.enableJobLength = this.admnStngs.merchant_settings?.bookings?.enable_job_length;
			this.enable_job_length = this.admnStngs.merchant_settings?.bookings?.enable_job_length;
			if(this.globals.enableJobLength == 'no'){
				this.globals.checkWHend = 'no'
			}
		}
		else{
			this.globals.enableJobLength = 'yes'
		}


	 	if(this.admnStngs.merchant_settings?.bookings?.scheduling_range == 'day' && this.admnStngs.merchant_settings?.bookings?.recurring_bookings_count ){
			this.globals.totalBookingsCount = this.admnStngs.merchant_settings?.bookings?.recurring_bookings_count;
		}

		if(this.admnStngs.merchant_settings?.providers?.scheduling_type){
			this.globals.scheduleType = this.admnStngs.merchant_settings?.providers?.scheduling_type;
		}
		if(this.admnStngs.merchant_settings?.bookings?.check_spots_availability_for){
			this.globals.spotCheckSettings = this.admnStngs.merchant_settings?.bookings?.check_spots_availability_for;
		}
		if(this.admnStngs.merchant_settings?.bookings?.skip_holidays){
			this.globals.skipHolidays = this.admnStngs.merchant_settings?.bookings?.skip_holidays;
		}

	 	if(typeof(this.admnStngs.merchant_settings?.providers?.override_provider_pairing) == 'undefined' || this.admnStngs.merchant_settings?.providers?.override_provider_pairing == false){
			this.globals.allPair = false;
		}
		if(this.admnStngs.merchant_settings?.providers?.provider_max_time && this.admnStngs.merchant_settings?.providers?.provider_max_time > 0){
			this.globals.providerMaxTime = this.admnStngs.merchant_settings?.providers?.provider_max_time;
		}

		if(this.admnStngs.merchant_settings?.providers?.check_spot_limits && this.admnStngs.merchant_settings?.providers?.check_spot_limits == 'yes' ){
			this.globals.checkSpotLimits = true
		}

		if(this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.providers?.divide_providers_job_length ){
			this.globals.divideJobLength = this.admnStngs.merchant_settings?.providers?.divide_providers_job_length
		}

		if(this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.providers?.provider_availability_based_on ){
			this.globals.providerAvailabilityBasedOn = this.admnStngs.merchant_settings?.providers?.provider_availability_based_on
		}

		if(this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.bookings?.skip_unavailable_booking ){
			this.globals.skipUnavailableBooking = this.admnStngs.merchant_settings?.bookings?.skip_unavailable_booking
		}

		if(this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.scheduling && this.admnStngs?.merchant_settings?.scheduling?.allow_same_provider_on_cust_resch ){
			this.globals.allowSameProviderResc = this.admnStngs.merchant_settings?.scheduling?.allow_same_provider_on_cust_resch
		}

		this.globals.checkAvailabilityFor = this.admnStngs.merchant_settings?.bookings?.check_availability_for ? this.admnStngs.merchant_settings?.bookings?.check_availability_for  : 'all'

		// this.globals.availabilityRange = this.admnStngs.merchant_settings.bookings.availability_range ? this.admnStngs.merchant_settings.bookings.availability_range : 6
	}

	/* functions to build days and dates arrays for month. used in scheduling calendar */
	daysCounter(n: number): any[] {
		return Array(n);
	}
	monthDatesCounter(startDateYMD:any,endDateYMD:any){
		let startDate = dayjs(startDateYMD)
		let endDate = dayjs(endDateYMD)
		let datesArray = this.getDates(startDate,endDate,1,'daily',31)
		return datesArray;
	}

	/* function to return days not applicable for frequency */
	public disableFreqDays(freq:any){
		let exclude_days :any = []
		exclude_days['daily_no_saturday_sunday'] = [0,6];
		exclude_days['daily_no_sunday'] = [0];
		exclude_days['every_monday_friday'] = [0,2,3,4,6];
		exclude_days['every_monday_wednesday_friday'] = [0,2,4,6];
		exclude_days['every_tuesday_and_thursday'] = [0,1,3,5,6];
		exclude_days['saturday_sunday'] = [1,2,3,4,5];
		exclude_days['every_tuesday_wednesday_friday'] = [0,1,4,6];
		exclude_days['every_monday_wednesday'] = [0,2,4,5,6];
		exclude_days['every_monday_thursday'] = [0,2,3,5,6];
		if(exclude_days[freq]){
			return exclude_days[freq];
		}
		return []
	}

	/**
	 * Functionto check the visibility of previous provider option on form.
	 */
	public checkVisibilityOfPreviousSelected(previousProviders:any, availableProviderIds:any) {
		if (previousProviders && previousProviders.length > 0 && availableProviderIds && (availableProviderIds).length > 0) {
			for(let provider of previousProviders) {
				for(let availProvider of availableProviderIds) {
					if (provider.id == availProvider) {
						return true;
					}
				}
			}
		}
		return false;
	}

	/* Function to check if customer can select specific provider */
	public canCustomerSelectSpecificProvider(admnStngs:any){
		if (admnStngs && admnStngs.merchant_settings && admnStngs.merchant_settings?.bookings?.allow_customer_schedule_specific_provider?.enable == 'yes') {
			if (admnStngs.merchant_settings?.bookings?.allow_customer_schedule_specific_provider?.display_on == 'both') {
				return true;
			} else if (admnStngs.merchant_settings.bookings?.allow_customer_schedule_specific_provider?.display_on == 'backend' && (this.currentUser && this.currentUser.token)) {
				return true;
			} else if (admnStngs.merchant_settings?.bookings?.allow_customer_schedule_specific_provider.display_on == 'frontend' && !this.currentUser) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	/**
	 * Functionto check the visibility of join waiting list option on form.
	 */
	public canCustomerJoinWaitingList(admnStngs:any) {
		if(admnStngs && admnStngs.merchant_settings && admnStngs.merchant_settings?.bookings && admnStngs.merchant_settings?.bookings?.show_waiting_list && admnStngs.merchant_settings?.bookings?.show_waiting_list == 'yes'){
			return true;
		}
		return false;
	}


	/* function to prepare the data required to filter available providers which will be used by other functions to get the available/applicable providers list */
	/* Shortform for this function APF and will be used as prefix to form specific function */
	public availProviderFilters(formValues:any){
		let customerId = 0;
		if(formValues.uid){
			customerId = formValues.uid;
		}
		let selectedZipCode = formValues.zipcode;
		let extraParam = [];
		if(formValues.extras && (formValues.extras).length > 0){
			for(let extra of formValues.extras){
				extraParam.push(extra.id);
			}
		}
		let commonFilters = {
			customer_id : customerId,
			industry_id : +formValues.industry_id,
			form_id : +formValues.form_id,
			location : formValues.location_type,
			location_id : formValues.location_id,
			base_location_id : formValues.base_location_id,
			zipcode : selectedZipCode,
			service_category : formValues.service_category,
			frequency : formValues.frequency,
			extras : extraParam,
			is_customer : true,
			spot_count : formValues.spot_count,
		}
		let availableProvidersData : any
		switch(formValues.form_id){
			case(2):{
				availableProvidersData = this.APFFormTwo(commonFilters, formValues);
				break;
			}
			case(3):{
				availableProvidersData = this.APFFormThree(commonFilters, formValues);
				break;
			}
			default :{
				availableProvidersData = this.APFForm(commonFilters, formValues);
				break;
			}

		}
		return availableProvidersData;
	}

	/* function to prepare available provider filter for form one, four and five */
	APFForm(commonFilters:any,formValues:any){
		let pricingParam = [];
		if(formValues.pricing_parameter && (formValues.pricing_parameter).length > 0){
			for(let param of formValues.pricing_parameter){
				if(param.quantity){
					pricingParam.push(param.quantity);
				}
			}
		}
		let excludeParam = [];
		if(formValues.partial_cleaning && (formValues.partial_cleaning).length > 0){
			for(let exclude of formValues.partial_cleaning){
				excludeParam.push(exclude.id);
			}
		}
		commonFilters.pricing_parameters = pricingParam
		commonFilters.exclude_parameters = excludeParam
		return commonFilters;
	}

	/* function to prepare available provider filter for form two */
	APFFormTwo(commonFilters:any,formValues:any){
		let itemsParam : any = [];
		let packageParam : any = [];
		let packageAddons : any = [];
		let packageCount : any = {};
		if(formValues.items && (formValues.items).length > 0){
			for(let item of formValues.items){
				itemsParam.push(item.id);
				if(item.packages && (item.packages).length > 0){
					for(let packageList of item.packages){
						if(!packageParam.includes(packageList.id)){
							packageParam.push(packageList.id);
						}
						if(packageList.package_addons && (packageList.package_addons).length >0 ){
							for(let packageAddon  of packageList.package_addons){
								if(!packageAddons.includes(packageAddon.id)){
									packageAddons.push(packageAddon.id);
								}
							}
						}

						let packageId = (packageList.id).toString();
						let flag = 0;
						for(let key in packageCount){
							if(key == (packageList.id).toString()){
								flag = 1;
							}
						}
						if(flag){
							packageCount[packageId] = packageCount[packageId] + packageList.quantity;
						}
						else{
							packageCount[packageId] = packageList.quantity;
						}
					}
				}
			}
		}
		if(itemsParam.includes(0)){
			itemsParam = null;
		}
		commonFilters.items = itemsParam
		commonFilters.packages = packageParam
		commonFilters.package_addons = packageAddons
		commonFilters.package_count = packageCount
		return commonFilters;
	}

	/* function to prepare available provider filter for form three */
	APFFormThree(commonFilters:any,formValues:any){
		let itemsParam = [];
		let itemsCount : any = {};
		// let addonParam : any = [];
		if(formValues.items && (formValues.items).length > 0){
			for(let item of formValues.items){
				itemsParam.push(item.id);
				let itemId = (item.id).toString();
				let flag = 0;
				if(itemsCount && itemsCount.length > 0){
					for(let key in itemsCount){
						if(key == (item.id).toString()){
							flag = 1;
						}
					}
				}
				if(flag){
					itemsCount[itemId] = itemsCount[itemId] + item.quantity;
				}
				else	{
					itemsCount[itemId] = item.quantity;
				}

				/*for(let addonList of item.addons){
					if(!addonParam.includes(addonList.id)){
						addonParam.push(addonList.id);
					}
				}*/
			}
		}
		commonFilters.items = itemsParam
		commonFilters.items_count = itemsCount
		return commonFilters;
	}

	/*
	 * Get recurring booking scheduling range
	 */
	getScheduleRange(): any {
		let scheduleRange = 182;
		let scheduleRangeSetting = this.admnStngs.merchant_settings?.bookings?.scheduling_range ? this.admnStngs.merchant_settings?.bookings?.scheduling_range : 'halfyear';
		switch (scheduleRangeSetting){
			case 'day':
				scheduleRange = 1
				if(this.admnStngs.merchant_settings?.bookings?.recurring_bookings_count && this.admnStngs.merchant_settings?.bookings?.recurring_bookings_count > 1){
					scheduleRange = 182
				}
				break;
			case 'month':
				scheduleRange = 31
				break;
			case 'halfyear':
				scheduleRange = 182
				break;
		}
		return scheduleRange;
	}
	/*
	* Function to  create recurring dates
	*/
	getRecurringDates(startDateYMD: any, freqOccurance: any, scheduleRange: any, totalBookingsCount: any = 12, holidays:any = [], skipHolidays:string='no'): any{
		let startDate = dayjs(startDateYMD);
		/* set max limit to 6 months = 182 days for booking */
		let maxDaysForRecurring = scheduleRange;
		let stopDate = dayjs(startDate).add(maxDaysForRecurring,'days');
		let allDates;

		if(freqOccurance == 'onetime'){
			allDates = [];
			allDates.push(startDate.format("YYYY-MM-DD"))
		}
		if(typeof(this.consistentFreq[freqOccurance]) != 'undefined'){
			// console.log(222,freqOccurance)
			allDates = this.getDates(startDate, stopDate,this.consistentFreq[freqOccurance],'', totalBookingsCount, holidays,skipHolidays);
		}
		if((this.inConsistentFreq).includes(freqOccurance)){
			// console.log(111,freqOccurance)
			allDates = this.getDates(startDate, stopDate,1,freqOccurance, totalBookingsCount, holidays,skipHolidays);
		}
		return allDates;
	}
	/**
	 * Function to get dates.
	 * @param startDate
	 * @param stopDate
	 * @param days
	 * @param freq
	 * @param totalBookingsCount
	 * @returns
	 */
	getDates(startDate: any, stopDate: any,days: any,freq: any, totalBookingsCount: any, holidays:any = [], skipHolidays:string='no'): any{
		let dateArray :any = [];
		let currentDate = startDate;
		let temp = startDate;
		/*** Array to control in-consistent requencies*/
		let exclude_days = this.disableFreqDays(freq);
		while (temp.unix() <= stopDate.unix()){
			let tempdate = currentDate.format("YYYY-MM-DD")
			if((holidays).includes(tempdate) && skipHolidays == 'yes'){
				// this condition is to skip the holiday, the date will not be pushed in the array for holiday
			}
			else{
				if(freq) {
					if(!exclude_days.includes(currentDate.day())){
						dateArray.push(tempdate)
					}
				}else {
					dateArray.push(tempdate)
				}
			}
			currentDate = dayjs(currentDate).add(days,'days');
			temp = dayjs(temp).add(days,'days');
			if( dateArray.length == totalBookingsCount ){
				break;
			}
		}
		return dateArray;
	}

	/**
	* Function to get date in Y-m-d format.
	*/
	getDateYMD(dateobj: any): any {
		let tempdate = dateobj.getUTCFullYear() + '-' + ('0' + (dateobj.getUTCMonth() + 1)).slice(-2) + '-' + ('0' + dateobj.getUTCDate()).slice(-2);
		return tempdate;
	}

	/* Function to get applicable providers, team members and break times */
	getApplicableProviders(data : any): Observable<any>{
		return this.apiServ.callApi('POST', 'applicableProviders', data).pipe(takeUntil(this.destroy));
	}

	/* Function to get applicable providers data like working hours, team members and break times */
	getApplicableProvidersData(data : any): Observable<any>{
		// return this.apiServ.callApi('POST', 'applicableProvidersData', data).pipe(takeUntil(this.destroy));
		return this.apiServ.callApi('POST', 'applicableProviderData', data).pipe(takeUntil(this.destroy));
	}

	/*
	* Function to get the holidays in a date range
	*/
  	getHolidays(data: any): Observable<any> {
		return this.apiServ.callApi('POST', 'holidaysByDateRange', data).pipe(takeUntil(this.destroy));
	}

	/*
	* Function to get the holidays in a date range
	*/
  	getBookingsCheckSpots(data: any): Observable<any> {
		return this.apiServ.callApi('POST', 'bookingsByDateRange', data).pipe(takeUntil(this.destroy));
	}
	getBookingsToCheckSpots(data: any): Observable<any> {
		return this.apiServ.callApi('POST', 'bookingsForDates', data).pipe(takeUntil(this.destroy));
	}

	/* Parse bookings for dates and maintain per provider bookings, this array will be used to check the spot limits */
	// parseBookingsForDates(bookings:any, teams:any=null, members:any=null,location_id:any=0){
	parseBookingsForDates(bookings:any, location_id:any=0){
		let bookingsForDates : any = {}
		// console.log('dayjs 1',dayjs())
		// let checkTeamAvailability = (this.admnStngs.merchant_settings.providers.check_team_availability && this.admnStngs.merchant_settings.providers.check_team_availability == 'yes')
		if(bookings){
			// for(let booking of bookings){
			for(let i in bookings){
				let booking = bookings[i]
				// console.log('booking', booking)
				if((!this.globals.providerAvailabilityBasedOn || this.globals.providerAvailabilityBasedOn == "all_location") || (this.globals.providerAvailabilityBasedOn && this.globals.providerAvailabilityBasedOn == "location" && booking.location_id && booking.location_id == location_id)){
					if(bookingsForDates[booking.booking_date] === undefined){
						bookingsForDates[booking.booking_date] = {}
					}
					if(bookingsForDates[booking.booking_date][booking.arrival_time] === undefined){
						bookingsForDates[booking.booking_date][booking.arrival_time] = []
					}
					bookingsForDates[booking.booking_date][booking.arrival_time].push(booking._id)
				}
			}
		}
		return {"bookingsForDates":bookingsForDates};
	}

	/* Function to prepare daily spot settings array, it will be used to check spot availability based on limit */
	parseDailySettings(dailySetting: any){
		let dailySettings : any = {}
		if(dailySetting && dailySetting != null && dailySetting.length > 0){
			for(let spot of dailySetting){
				if(dailySettings[spot.date] === undefined){
					dailySettings[spot.date] = {}
				}
				if(spot.status == 0){
					dailySettings[spot.date][spot.spot_slug] = 0
				}
				else{
					dailySettings[spot.date][spot.spot_slug] = spot.limit
				}
			}
		}
		return dailySettings;
	}

	/* function to prepare providers list available for pairing */
	preparePairableProviders(providers: any){
		let pairableProviders:any = [];
		if(providers){
			for(let provider in providers){
				// console.log('111',provider)
				if(!(pairableProviders).includes(provider) && providers[provider]['can_combine_in_pair'] == 'yes'){
					pairableProviders.push(+provider);
				}
			}
		}
		// console.log('pairableProviders',pairableProviders)
		return pairableProviders
	}

	/* function to prepare working hours per provider, it will be used to check the spots availability for booking */
	prepareWorkingHours(providerWH : any){
		// console.log('providerWH',providerWH)
		let allwh : any = {};
		dayjs.extend(utc);
		/* use this.allwh for all working hours of available providers */
		Object.keys(providerWH).forEach(pid => {
			if(allwh[pid] === undefined){
				allwh[pid] = {};
			}
			Object.keys(providerWH[pid]).forEach(timestamp => {
				let tempdate = dayjs.unix(+timestamp).utc().format("YYYY-MM-DD");
				if(allwh[pid][tempdate] === undefined){
					allwh[pid][tempdate] = {};
				}
				Object.keys(providerWH[pid][timestamp]).forEach(providerwh => {
					if(allwh[pid][tempdate][providerWH[pid][timestamp][providerwh].start_time] === undefined){
						allwh[pid][tempdate][providerWH[pid][timestamp][providerwh].start_time] = {}
					}
					allwh[pid][tempdate][providerWH[pid][timestamp][providerwh].start_time].start = providerWH[pid][timestamp][providerwh].start_time;
					allwh[pid][tempdate][providerWH[pid][timestamp][providerwh].start_time].end = providerWH[pid][timestamp][providerwh].end_time;
				});
			});
		});

		/*for(let pid in providerWH){
			if(allwh[pid] === undefined){
				allwh[pid] = {};
			}
			for(let timestamp in providerWH[pid]){
				let tempdate = dayjs.unix(+timestamp).utc().format("YYYY-MM-DD");
				if(allwh[pid][tempdate] === undefined){
					allwh[pid][tempdate] = {};
				}
				for(let providerwh in providerWH[pid][timestamp]){
					if(allwh[pid][tempdate][providerWH[pid][timestamp][providerwh].start_time] === undefined){
						allwh[pid][tempdate][providerWH[pid][timestamp][providerwh].start_time] = {}
					}
					allwh[pid][tempdate][providerWH[pid][timestamp][providerwh].start_time].start = providerWH[pid][timestamp][providerwh].start_time;
					allwh[pid][tempdate][providerWH[pid][timestamp][providerwh].start_time].end = providerWH[pid][timestamp][providerwh].end_time;
				}
			}
		}*/
		return allwh;
	}

	/* Function to prepare spots for spots limit based scheduling or waiting list */
	prepareWLSpots(allSpots:any,month:number){
		console.log('allSpots',allSpots,month)
	}

	/* function to prepare data for calendar build */
	getCalendar() {
		let months : any = []
		let monthActiveDates : any = {};
		let currentDate = dayjs()
		let currentDateYMD = currentDate.format("YYYY-MM-DD");
		let year = currentDate.format("YYYY");
		let startDate = dayjs().startOf('month');
		let firstDate = startDate.startOf('month').format("D");
		let lastDate = dayjs(startDate).endOf('month').format("D");
		let day = startDate.day();
		let month : any = []
		let monthArray = []
		month['name'] = currentDate.format("MMMM")
		month['firstDate'] = firstDate
		month['lastDate'] = lastDate
		month['year'] = year
		month['startday'] = day
		month['daystoskip'] = this.daysCounter(+day)
		month['days'] = this.daysCounter(+lastDate)
		month['month'] = currentDate.format("MM")
		months.push(month)
		monthArray.push(month['month'])
		let lastDateYmd = dayjs(startDate).endOf('month').format("YYYY-MM-DD");
		// if(+(this.globals.availabilityRange) > 1){
		// 	for(let i = 0; i <= (+(this.globals.availabilityRange)-2); i++) {
			for(let i = 0; i <= 4; i++) {
				let month : any = []
				startDate = dayjs(startDate).add(1, 'month');
				firstDate = startDate.startOf('month').format("D");
				lastDate = dayjs(startDate).endOf('month').format("D");
				year = startDate.format("YYYY")
				day = startDate.day();
				month['name'] = startDate.format("MMMM")
				month['firstDate'] = firstDate
				month['lastDate'] = lastDate
				month['year'] = year
				month['startday'] = day
				month['daystoskip'] = this.daysCounter(+day)
				month['days'] = this.daysCounter(+lastDate)
				month['month'] = startDate.format("MM")
				months.push(month)
				monthArray.push(month['month'])
			}
		// }
		monthActiveDates[+currentDate.format("MM")] = this.monthDatesCounter(currentDateYMD,lastDateYmd);
		return {"months":months,"monthActiveDates":monthActiveDates,"monthArray":monthArray}
	}

	/* function to prepare scheduling spots if they are not based on limit */
	prepareSpots(month:number,allDatesToCheck:any,bookingSpots:any){
		let currentDate = dayjs();
		let currentDateYMD = currentDate.format("YYYY-MM-DD")
		let currentHour : any = currentDate.format('H')
		currentDate = currentDate.startOf('date');
		currentHour = parseInt(currentHour)*100;
		if(this.globals.allowSameDayBooking == 'yes' && this.globals.sameDayBookingTimeLimit == 'yes' && this.globals.sameDayBookingLimitTimeValue){
			let numberOfHours = parseInt(this.globals.sameDayBookingLimitTimeValue)*100;
			currentHour = currentHour + numberOfHours;
		}

		let perDateSpots : any = {}
		let perDateAvailSpots : any = {}
		let notAvailableDates : any = []
		if(allDatesToCheck && allDatesToCheck[+month] && (allDatesToCheck[+month]).length > 0){
			for(let date of allDatesToCheck[+month]){
				let day = (dayjs(date).format('dddd')).toLowerCase();
				perDateSpots[date] = [];
				if(bookingSpots && bookingSpots[day] && (Object.keys(bookingSpots[day])).length > 0){
					for(let s in bookingSpots[day]) {
						// To block the current date past spots.
						if (s <= currentHour && this.globals.allowSameDayBooking == 'yes' && date == currentDateYMD) {
							continue;
						}
						perDateSpots[date].push(s)
					}
				}
			}
		}
		return {"perDateSpots":perDateSpots,"perDateAvailSpots":perDateAvailSpots,"notAvailableDates":notAvailableDates}
	}

	/* function to prepare manual scheduling spots */
	prepareManualSpots(month:number,allDatesToCheck:any,bookingSpots:any,dailySettings:any,bookingsForDates:any,monthActiveDates:any,perDayRecurringDates:any,is_waiting_list:boolean=false){
		let currentDate = dayjs();
		let currentDateYMD = currentDate.format("YYYY-MM-DD")
		let currentHour : any = currentDate.format('H')
		currentDate = currentDate.startOf('date');
		currentHour = parseInt(currentHour)*100;
		if(this.globals.allowSameDayBooking == 'yes' && this.globals.sameDayBookingTimeLimit == 'yes' && this.globals.sameDayBookingLimitTimeValue){
			let numberOfHours = parseInt(this.globals.sameDayBookingLimitTimeValue)*100;
			currentHour = currentHour + numberOfHours;
		}
		let perDateSpots : any = {}
		// let perDateAvailSpots : any = {}
		// let notAvailableDates : any = []
		let limit = 20;
		if(allDatesToCheck && allDatesToCheck[+month] && (allDatesToCheck[+month]).length > 0){
			for(let date of allDatesToCheck[+month]){
				let day = (dayjs(date).format('dddd')).toLowerCase();
				perDateSpots[date] = []
				// TODO
				if(bookingSpots && bookingSpots[day]){
					for(let s in bookingSpots[day]) {
						// To block the current date past spots.
						if (s <= currentHour && this.globals.allowSameDayBooking == 'yes' && date == currentDateYMD) {
							continue;
						}
						limit = (bookingSpots[day][s]['limit'] || bookingSpots[day][s]['limit'] == 0) ? bookingSpots[day][s]['limit'] : 20;
						if(typeof(dailySettings[date]) !== 'undefined' && typeof(dailySettings[date][s]) !== 'undefined'){
							limit = dailySettings[date][s]
						}
						if(limit > 0 && (typeof(bookingsForDates[date]) === 'undefined' || typeof(bookingsForDates[date][s]) === 'undefined' || (typeof(bookingsForDates[date]) !== 'undefined' && typeof(bookingsForDates[date][s]) !== 'undefined' && limit > bookingsForDates[date][s].length))){
							perDateSpots[date].push(s)
						}
						else if(is_waiting_list == true){
							// this.wlSpots[this.selectedDate].push(s)
							perDateSpots[date].push(s)
						}
					}
				}
			}
		}
		let response = this.prepareNotAvailDates(month, perDateSpots, monthActiveDates, perDayRecurringDates)
		return {"perDateSpots":perDateSpots,"perDateAvailSpots":response.perDateAvailSpots,"notAvailableDates":response.notAvailableDates}
	}

	/* function to prepare no available dates based on spots limits or availability */
	prepareNotAvailDates(month:number, perDateSpots:any, monthActiveDates:any, perDayRecurringDates:any){
		let perDateAvailSpots : any = {}
		let notAvailableDates : any = []
		if(perDateSpots){
			let tempSpotArray : any = [];
			if(monthActiveDates && monthActiveDates[+month] && (monthActiveDates[+month]).length > 0){
				for(let date of monthActiveDates[+month]){
					// console.log('date -- ', date)
					if(!perDateSpots[date] || (perDateSpots[date]).length == 0){
						(notAvailableDates).push(date)
					}
					else{
						tempSpotArray = [...perDateSpots[date]];
						if(tempSpotArray && tempSpotArray.length > 0){
							for(let spot of tempSpotArray){
								if(perDayRecurringDates && perDayRecurringDates[date] && (perDayRecurringDates[date]).length > 1){
									for(let dateRec of perDayRecurringDates[date]){
										if(!(perDateSpots[dateRec]).includes(spot)){
											let index = (perDateSpots[date]).indexOf(spot, 0);
											if (index > -1) {
												(perDateSpots[date]).splice(index, 1);
											}

										}
									}
								}
							}
						}
						if((perDateSpots[date]).length == 0){
							(notAvailableDates).push(date)
						}
					}
					perDateAvailSpots[date] = perDateSpots[date] ? [...perDateSpots[date]] : []
				}
			}
			// console.log(444,perDateAvailSpots)
		}
		return {"perDateAvailSpots":perDateAvailSpots,"notAvailableDates":notAvailableDates}
	}

	/**
	 * Function to setup booking spot end array.	 */
	setupBookingSpotEndArray(bookingSpots:any,jobBeginTime:string){
		let bookingSpotEndArray : any = {};
		if(bookingSpots){
			for(let spotday in bookingSpots){
				if(bookingSpotEndArray[spotday] === undefined){
					bookingSpotEndArray[spotday] = {}
				}
				for(let spotslug in bookingSpots[spotday]){
					let endtimeoffset = this.startOfSlotRange(bookingSpots[spotday][spotslug].start_time,bookingSpots[spotday][spotslug].end_time, jobBeginTime)
					if(bookingSpotEndArray[spotday][spotslug] === undefined){
						bookingSpotEndArray[spotday][spotslug] = []
					}
					bookingSpotEndArray[spotday][spotslug].push(endtimeoffset)
				}
			}
		}
		return bookingSpotEndArray
	}

	/**
	 * Function to check if day discount valid for selected date or not.
	 * @param year
	 * @param month
	 * @param day
	 * @param dateDiscountsArray
	 * @param dayDiscountsArray
	 * @param dayDiscountBookings
	 * @param prefilledData
	 * @returns
	 */
	isDayDiscountValid(year:any, month:any, day:any, dateDiscountsArray:any, dayDiscountsArray: any, dayDiscountBookings: any, prefilledData: any): any{
		let calDay = ("00" + day).slice(-2)
		let calDate = year+'-'+month+'-'+calDay
		if(calDate && !prefilledData?.exclude_day_discount){
			return this.dayDiscountDesc(calDate, dateDiscountsArray, dayDiscountsArray, dayDiscountBookings, prefilledData);
		}
		return '';
	}
	/**
	 * Function to return the description of day discount.
	 * @param calDate
	 * @param dateDiscountsArray
	 * @param dayDiscountsArray
	 * @param dayDiscountBookings
	 * @param prefilledData
	 * @returns
	 */
	dayDiscountDesc(calDate: any, dateDiscountsArray:any, dayDiscountsArray: any, dayDiscountBookings: any, prefilledData: any): any{
		if(calDate){
			let calDayName = this.dayFromDate(calDate);
			let selectedDayDiscount: any;
			if(dateDiscountsArray && dateDiscountsArray[calDate]){
				selectedDayDiscount = dateDiscountsArray[calDate];
			}else if(dayDiscountsArray && dayDiscountsArray[calDayName.toLowerCase()]){
				selectedDayDiscount = dayDiscountsArray[calDayName.toLowerCase()];
			}
			if(selectedDayDiscount){
				if(prefilledData && prefilledData.day_discount && prefilledData.day_discount.discount && prefilledData.booking_date == calDate){
					return selectedDayDiscount.description
				}else if(!dayDiscountBookings || !dayDiscountBookings[calDate] || (dayDiscountBookings && dayDiscountBookings[calDate] && selectedDayDiscount.bookings_count && dayDiscountBookings[calDate] < selectedDayDiscount.bookings_count)){
					return selectedDayDiscount.description
				}
			}
		}
		return '';
	}
	/**
	 * Function to check the bookings count for spot.
	 * @param dayDiscountBookings
	 * @param selectedDate
	 * @param selectedDayDiscount
	 * @param prefilledData
	 * @returns
	 */
	checkBookingsCount(dayDiscountBookings: any, selectedDate: any, selectedDayDiscount: any, prefilledData: any){
		if(prefilledData && prefilledData.day_discount && prefilledData.day_discount.discount && selectedDate == prefilledData.booking_date){
			return true;
		}else if(!dayDiscountBookings || !dayDiscountBookings[selectedDate] || (dayDiscountBookings && dayDiscountBookings[selectedDate] && selectedDayDiscount.bookings_count && dayDiscountBookings[selectedDate] < selectedDayDiscount.bookings_count)){
			return true;
		}
		return false;
	}
	/**
	 * Function to show the slot discount.
	 * @param selectedDate
	 * @param slot
	 * @param dateDiscountsArray
	 * @param dayDiscountsArray
	 * @param dayDiscountBookings
	 * @param prefilledData
	 * @returns
	 */
	// eslint-disable-next-line max-params
	showSlotDiscount(selectedDate: any, slot: any, dateDiscountsArray: any, dayDiscountsArray: any, dayDiscountBookings: any, prefilledData: any, type: string = "calendar"): any{
		let selectedDay = this.dayFromDate(selectedDate);
		if(selectedDate && !prefilledData?.exclude_day_discount){
			let selectedDayDiscount;
			if(dateDiscountsArray && dateDiscountsArray[selectedDate]){
				selectedDayDiscount = dateDiscountsArray[selectedDate];
			}else if(dayDiscountsArray && dayDiscountsArray[selectedDay.toLowerCase()]){
				selectedDayDiscount = dayDiscountsArray[selectedDay.toLowerCase()];
			}
			if(selectedDayDiscount && selectedDayDiscount.spots){
				if(selectedDayDiscount.spots[+slot]){
					if(selectedDayDiscount.spots[+slot].discount){
						if(this.checkBookingsCount(dayDiscountBookings, selectedDate, selectedDayDiscount, prefilledData)){
							// eslint-disable-next-line max-depth
							if(type == 'slot'){
								return selectedDayDiscount.spots[+slot];
							}else{
								// eslint-disable-next-line max-depth
								if(selectedDayDiscount.spots[+slot].discount_type == 'fixed'){
									return this.utilServ.amountWithCurrency(selectedDayDiscount.spots[+slot].discount, 'Save');
								}else{
									return `<span>Save `+(+selectedDayDiscount.spots[+slot].discount).toFixed(2)+`%</span>`;
								}
							}
						}
					}
				}else if(this.checkForNewAddedSpot(selectedDayDiscount, slot)){
					let newspot = Object.keys(selectedDayDiscount.spots)[0];
					let spotDiscount = selectedDayDiscount.spots[newspot];
					if(spotDiscount.discount){
						if(this.checkBookingsCount(dayDiscountBookings, selectedDate, selectedDayDiscount, prefilledData)){
							// eslint-disable-next-line max-depth
							if(type == 'slot'){
								return spotDiscount;
							}else{
								// eslint-disable-next-line max-depth
								if(spotDiscount.discount_type == 'fixed'){
									return this.utilServ.amountWithCurrency(spotDiscount.discount, 'Save');
								}else{
									return `<span>Save `+(+spotDiscount.discount).toFixed(2)+`%</span>`;
								}
							}
						}
					}
				}
			}
		}
		return '';
	}
	/**
	 * Function to check for new added spot.
	 * @param selectedDayDiscount
	 * @param slot
	 * @returns
	 */
	checkForNewAddedSpot(selectedDayDiscount: any, slot: any): boolean{
		if(selectedDayDiscount.spot_type && selectedDayDiscount.spot_type == "range" && selectedDayDiscount.spot_start && selectedDayDiscount.spot_end && (+slot >= selectedDayDiscount.spot_start && +slot <= selectedDayDiscount.spot_end)){
			return true;
		}
		return false;
	}
	/**
	 * Function to get the day from date.
	 * @param bdate
	 * @param flag = true for short day like "Mon"
	 */
	dayFromDate(bdate: any, flag : any = null){
		let day = dayjs(bdate).format('dddd')
		if(flag){
			day = dayjs(bdate).format('dd')
		}
		return day
	}

	/* function to build spot name */
	buildSpotName(allSpots:any,spot:any,date:string){
		let day = (dayjs(date).format('dddd')).toLowerCase();
		let spotName : string = spot;
		// console.log('allSpots', allSpots, day, spot)
		if(allSpots && allSpots[day] && allSpots[day][spot]){
			spotName = this.utilServ.convertTime24HFormat(allSpots[day][spot]['name']);
			if(allSpots[day][spot]['end_time'] && this.utilServ.timeFormat === '24'){
				spotName = this.spotToTime(allSpots[day][spot]['start_time']) + " - " + this.spotToTime(allSpots[day][spot]['end_time']);
			}
		}
		else{
			spotName = this.spotToTime(spot)
		}
		// console.log('date', spot, date, spotName, day)
		return spotName
	}
	spotToTime(spot:any){
		let Meridian;
		let Hours;
		let Minutes;

		let hours = Math.floor(+spot/100);
		let minutes = +spot%100;
		if(hours >= 12){
			Meridian = "PM";
			Hours = hours;
			(hours > 12)? Hours = hours - 12 : '';
			Minutes = minutes;
		} else {
			Meridian = "AM";
			Hours = hours;
			Minutes = minutes;
		}

		(Hours  < 10) ? Hours = '0'+ (Hours) : Hours = Hours;
		(minutes < 10) ? Minutes = '0'+ (Minutes) : Minutes = Minutes;
		let h = (+Hours > 0) ? Hours : '00';
		let m = (+Minutes > 0) ? Minutes : '00'
		let val = h+":"+m+" "+Meridian;
		return this.utilServ.convertTime24HFormat(val);
	}

	/* get break time to check the spot availability */
	getBreakTimeForAvailabilityCheck(pid:any,providerBreakTimes:any,location_id:any,location_type:any,zipcode:any,old_booking_loc_id:any,old_booking_loc_type:any,old_booking_zipcode:any){
		let break_time = 0;
		if(this.enable_job_length == 'no'){
			break_time = 0;
			return break_time;
		}
		if(providerBreakTimes[pid] && this.enable_job_length != 'no'){
			if(old_booking_loc_type == 'ML' && location_type == 'ML' && old_booking_loc_id == location_id){
				break_time = providerBreakTimes[pid].non_travel_time ? providerBreakTimes[pid].non_travel_time : 0;
			}
			else if(old_booking_loc_type == 'SA' && location_type == 'SA' && old_booking_zipcode == zipcode){
				break_time = providerBreakTimes[pid].non_travel_time ? providerBreakTimes[pid].non_travel_time : 0;
			}
			else{
				break_time = providerBreakTimes[pid].travel_time;
			}
		}
		return break_time;
	}

	/* function to check the provider availability based on working hours and bookings */
	checkProvidersAvailability(month:number, BKFrm:any, applicableProviderIds:any, allDatesToCheck:any, bookingsForProviders:any, providerSettings:any, providerWorkingHours:any, perDateSpots:any, pairableProviders:any, memberCount:any,bookinglength:number, minProvidersReq:number, allPair:boolean, enableJobLength:string,bookingSpotEndArray:any,divideJobLength:string,prefilledData: any){
		let currentDate = dayjs()
		let currentDateYMD = currentDate.format("YYYY-MM-DD");
		let location_id = BKFrm.controls['location_id'].value
		let perProviderSpots : any = {}
		let perSpotProviders : any = {}
		let providerSkipDates : any = {}
		let teamIds = []
		// let temp = 0
		let location_type = BKFrm.controls['location_type'].value
		let zipcode = BKFrm.controls['zipcode'].value;
		let break_time = 0
		let slotStartOffset = 0
		let is_time_adjusted:any = false;

		if(BKFrm.controls['adjust_time'].value){
			is_time_adjusted = BKFrm.controls['adjust_time'].value
		}
		if(this.globals.providerMaxTime > 0 && +bookinglength > this.globals.providerMaxTime){
			bookinglength = this.globals.providerMaxTime;
		}
		let bkingln = +bookinglength;
		if(applicableProviderIds){
			// for(let pid of applicableProviderIds){
			for(let i in applicableProviderIds){
				let pid = applicableProviderIds[i]
				// console.log('pid', pid)
				// if(pid == 7){
				// temp++
				if((minProvidersReq > 1 && allPair == false && !(pairableProviders).includes(pid) && (typeof(memberCount) == 'undefined' || typeof(memberCount[+pid]) == 'undefined')) || (typeof(memberCount) != 'undefined' && memberCount[+pid] < minProvidersReq)){
					teamIds.push(pid)
					continue;
				}
				if( perProviderSpots["1_"+pid] === undefined){
					perProviderSpots["1_"+pid] = {};
				}

				bkingln = +bookinglength;
				if(memberCount && memberCount[+pid] !== undefined && !is_time_adjusted && divideJobLength == 'yes'){
					bkingln = Math.ceil(+bookinglength/parseInt(memberCount[+pid]));
				}
				if(allDatesToCheck[+month]){
					// for(let dateToCheck of allDatesToCheck[+month]){
					for(let j in allDatesToCheck[+month]){
						let dateToCheck = allDatesToCheck[+month][j]
						// console.log('dateToCheck',dateToCheck, bookingsForProviders[pid][dateToCheck], providerWorkingHours[pid][dateToCheck])
						// temp++
						if( perSpotProviders[dateToCheck] === undefined){
							perSpotProviders[dateToCheck] = {};
						}
						let dayToCheck = (dayjs(dateToCheck).format('dddd')).toLowerCase();
						// for(let spotToCheck of perDateSpots[dateToCheck]){
						for(let k in perDateSpots[dateToCheck]){
							let spotToCheck = perDateSpots[dateToCheck][k]
							// console.log('spotToCheck', spotToCheck, dateToCheck)
							// temp++
							if(this.enable_job_length == 'no'){
								slotStartOffset = 0;
								bkingln = 0
							}
							else{
								slotStartOffset = (bookingSpotEndArray[dayToCheck] !== undefined && bookingSpotEndArray[dayToCheck][spotToCheck] !== undefined ) ? bookingSpotEndArray[dayToCheck][spotToCheck] : 0;
							}

							let spotStartTime = this.timeInTwentyFour(spotToCheck,slotStartOffset);
							// let spotStartTime = spotToCheck
							let tempspotend = this.timeInTwentyFour(spotStartTime,bkingln)
							let tempspotForWH = tempspotend;
							if(this.globals.checkWHend == 'no'){
								tempspotForWH = spotToCheck;
							}
							// console.log('slotStartOffset',pid, slotStartOffset,spotStartTime, tempspotend, bkingln)
							let slot_not_avail = 0;
							if(providerWorkingHours[pid] !== undefined && providerWorkingHours[pid][dateToCheck] !== undefined){
								// let whCount = 0
								// To exclude the date if same day off for provider
								if(currentDateYMD && (currentDateYMD == dateToCheck) && (providerSettings && providerSettings[pid] && providerSettings[pid].turn_off_same_day_jobs && providerSettings[pid].turn_off_same_day_jobs == 'yes')){
									slot_not_avail = 1;
									// whCount++
								}else{
									for(let wh in providerWorkingHours[pid][dateToCheck]){
										// temp++
										// console.log('wh',wh,dateToCheck, spotToCheck,)
										if(providerWorkingHours[pid][dateToCheck][wh].start <= spotToCheck && providerWorkingHours[pid][dateToCheck][wh].end >= tempspotForWH){
											slot_not_avail = 0;
											if(bookingsForProviders[pid] !== undefined && bookingsForProviders[pid][dateToCheck] !== undefined){
												// console.log('bookingsForProviders[pid][dateToCheck]',bookingsForProviders[pid][dateToCheck])
												for( let oldBooking in bookingsForProviders[pid][dateToCheck]){
													// temp++
													let old_booking_loc_type = bookingsForProviders[pid][dateToCheck][oldBooking].location_type;
													let old_booking_zipcode = bookingsForProviders[pid][dateToCheck][oldBooking].zipcode;
													let old_booking_loc_id = bookingsForProviders[pid][dateToCheck][oldBooking].location_id;

													/* get the travel time based on location of current booking and other booking on same date */
													break_time = this.getBreakTimeForAvailabilityCheck(pid,providerSettings,location_id,location_type,zipcode,old_booking_loc_id,old_booking_loc_type,old_booking_zipcode)
													if(bookingsForProviders[pid][dateToCheck][oldBooking].arrival_time <= spotStartTime){
														let tempOldBookingEnd = bookingsForProviders[pid][dateToCheck][oldBooking].end_time;
														if(enableJobLength == 'no' || bookingsForProviders[pid][dateToCheck][oldBooking].end_time == bookingsForProviders[pid][dateToCheck][oldBooking].start_time){
															tempOldBookingEnd = bookingsForProviders[pid][dateToCheck][oldBooking].start_time+1;
														}
														let old_booking_length = this.timeInTwentyFour(tempOldBookingEnd,break_time);
														if(old_booking_length > spotStartTime){
															// console.log('dateToCheck 11',dateToCheck,old_booking_length,'--1--',spotStartTime,'--2--',tempOldBookingEnd,'--3--',break_time, bookingsForProviders[pid][dateToCheck][oldBooking])
															slot_not_avail = 1;
															// whCount++
														}
													}
													else{
														let new_booking_end_time = this.timeInTwentyFour(tempspotend,break_time);
														if(new_booking_end_time > bookingsForProviders[pid][dateToCheck][oldBooking].start_time){
															// console.log('dateToCheck',dateToCheck,bookingsForProviders[pid][dateToCheck][oldBooking])
															slot_not_avail = 1;
															// whCount++
														}
													}
													// console.log('old_booking',break_time,slot_not_avail,spotStartTime)
												}
											}
											if(slot_not_avail == 0){
												if( perSpotProviders[dateToCheck][spotToCheck] === undefined){
													perSpotProviders[dateToCheck][spotToCheck] = [];
												}
												if( perProviderSpots["1_"+pid][spotToCheck] === undefined){
													perProviderSpots["1_"+pid][spotToCheck] = [];
												}

												(perSpotProviders[dateToCheck][spotToCheck]).push(pid);
												(perProviderSpots["1_"+pid][spotToCheck]).push(dateToCheck);
											}

											if(this.globals.skipUnavailableBooking && this.globals.skipUnavailableBooking == 'yes' && slot_not_avail == 1 ){
												if( providerSkipDates[pid] === undefined){
													providerSkipDates[pid] = {};
												}
												if( providerSkipDates[pid][spotToCheck] === undefined){
													providerSkipDates[pid][spotToCheck] = [];
												}
												providerSkipDates[pid][spotToCheck].push(dateToCheck)
											}
										}
										else {
											// console.log('dateToCheck 22',dateToCheck)
											slot_not_avail = 1;
											if(this.globals.skipUnavailableBooking && this.globals.skipUnavailableBooking == 'yes' && slot_not_avail == 1 ){
												if( providerSkipDates[pid] === undefined){
													providerSkipDates[pid] = {};
												}
												if( providerSkipDates[pid][spotToCheck] === undefined){
													providerSkipDates[pid][spotToCheck] = [];
												}
												providerSkipDates[pid][spotToCheck].push(dateToCheck)
											}
											// whCount++
										}
									}
								}
							}
							else{
								if(this.globals.skipUnavailableBooking && this.globals.skipUnavailableBooking == 'yes' ){
									if( providerSkipDates[pid] === undefined){
										providerSkipDates[pid] = {};
									}
									if( providerSkipDates[pid][spotToCheck] === undefined){
										providerSkipDates[pid][spotToCheck] = [];
									}
									providerSkipDates[pid][spotToCheck].push(dateToCheck)
								}
							}
						}
					}
				}
				// }
			}
		}

		if(prefilledData && prefilledData.booking_date && prefilledData.provider_ids[0] && prefilledData.provider_ids[0] != 0 && ((this.globals.allowSameProviderResc && this.globals.allowSameProviderResc == 'yes') || !this.globals.allowSameProviderResc) && (BKFrm.controls['provider_ids'].value).includes(prefilledData?.provider_ids[0])){
			if( perSpotProviders[prefilledData.booking_date] === undefined){
				perSpotProviders[prefilledData.booking_date] = {};
			}
			if( perSpotProviders[prefilledData.booking_date][prefilledData.arrival_time] === undefined){
				perSpotProviders[prefilledData.booking_date][prefilledData.arrival_time] = [];
			}

			if( perProviderSpots["1_"+prefilledData.provider_ids[0]] === undefined){
				perProviderSpots["1_"+prefilledData.provider_ids[0]] = {};
			}
			if( perProviderSpots["1_"+prefilledData.provider_ids[0]][prefilledData.arrival_time] === undefined){
				perProviderSpots["1_"+prefilledData.provider_ids[0]][prefilledData.arrival_time] = [];
			}

			if(!(perSpotProviders[prefilledData.booking_date][prefilledData.arrival_time]).includes(prefilledData.provider_ids[0])){
				(perSpotProviders[prefilledData.booking_date][prefilledData.arrival_time]).push(prefilledData.provider_ids[0]);
			}
			if(!(perProviderSpots["1_"+prefilledData.provider_ids[0]][prefilledData.arrival_time]).includes(prefilledData.booking_date)){
				(perProviderSpots["1_"+prefilledData.provider_ids[0]][prefilledData.arrival_time]).push(prefilledData.booking_date);
			}
		}

		// console.log('loop count',providerSkipDates)
		// console.log('perProviderSpots 11', perProviderSpots, perSpotProviders)
		return {"perSpotProviders":perSpotProviders,"perProviderSpots":perProviderSpots,'teamIds':teamIds,'providerSkipDates':providerSkipDates}
	}

	/* function to prepare available spots based on provider availability */
	// prepareAvailableSpots(perProviderSpots:any, perSpotProviders:any, perDayRecurringDates:any, minProvidersReq:number, checkAvailabilityFor:string,checkAvailabilityLimit:number=1){
	// prepareAvailableSpots(perProviderSpots:any, perSpotProviders:any, perDayRecurringDates:any, minProvidersReq:number){
	prepareAvailableSpots(perProviderSpots:any, perDayRecurringDates:any, minProvidersReq:number,teamIds:any,holidays:any,providerSkipDates:any){
		let perSpotAvailProviders : any = {}
		let perSpotAvailTeam : any = {}
		let checkAvailabilityLimit = 24
		if(this.globals.checkAvailabilityFor == 'first'){
			checkAvailabilityLimit = 1
		}
		// console.log('perDayRecurringDates', checkAvailabilityFor, checkAvailabilityLimit, perDayRecurringDates)
		// console.log('perSpotProviders', checkAvailabilityLimit, perDayRecurringDates ,perSpotProviders )
		// console.log('perProviderSpots', perProviderSpots)

		for(let tpid in perProviderSpots){
			let pid = ((tpid).split("1_"))[1]
			for(let spot in perProviderSpots[tpid]){
				for(let date in perDayRecurringDates){
					if( perSpotAvailProviders[date] === undefined){
						perSpotAvailProviders[date] = {};
					}
					if( perSpotAvailTeam[date] === undefined && teamIds && teamIds.includes(pid)){
						perSpotAvailTeam[date] = {};
					}
					let is_slot_available = 1
					// console.log('===========', pid, spot, date)
					let dateCount = 0;
					let skipDateCount = 0
					if(perDayRecurringDates[date] && (perDayRecurringDates[date]).length > 0){
						for(let recDate of perDayRecurringDates[date]){
							if((perProviderSpots[tpid][spot] && !(perProviderSpots[tpid][spot]).includes(recDate)) || holidays.includes(recDate)){
								// console.log('pid', pid, spot, date, recDate);
								is_slot_available = 0;
							}
							if(providerSkipDates && providerSkipDates[pid] && providerSkipDates[pid][spot] && (providerSkipDates[pid][spot]).includes(recDate) && holidays && !holidays.includes(recDate) && this.globals.skipUnavailableBooking && this.globals.skipUnavailableBooking == 'yes' && skipDateCount < 1 && dateCount > 0){
								// console.log('pid', pid, spot, date, recDate);
								is_slot_available = 1;
								skipDateCount++
							}
							if(dateCount == 0 && is_slot_available == 0){
								break;
							}
							dateCount++;
							if(checkAvailabilityLimit == dateCount){
								break;
							}
						}
					}
					if(is_slot_available == 1){
						if( perSpotAvailProviders[date][spot] === undefined){
							perSpotAvailProviders[date][spot] = [];
						}
						(perSpotAvailProviders[date][spot]).push(pid);

						if(teamIds && teamIds.includes(pid)){
							if( perSpotAvailTeam[date][spot] === undefined){
								perSpotAvailTeam[date][spot] = [];
							}
							(perSpotAvailTeam[date][spot]).push(pid);
						}
					}
				}
			}
		}
		// console.log('perSpotAvailProviders  22', perSpotAvailProviders, perSpotAvailTeam )
		let response = this.getProviderBasedNotAvailDates(perSpotAvailProviders, perDayRecurringDates, minProvidersReq,perSpotAvailTeam)
		// console.log('perSpotAvailProviders',perSpotAvailProviders,'minProvidersReq',minProvidersReq)
		return {"perSpotAvailProviders":perSpotAvailProviders,"perDateAvailSpots":response.perDateAvailSpots,"notAvailableDates":response.notAvailableDates}
	}

	/* get not available dates based on provider availability */
	getProviderBasedNotAvailDates(perSpotAvailProviders:any, perDayRecurringDates: any, minProvidersReq:number,perSpotAvailTeam:any){
		let notAvailableDates = []
		let perDateAvailSpots : any = {}
		// console.log('perSpotAvailProviders[date] 11', perSpotAvailProviders, perSpotAvailTeam )
		for(let date in perDayRecurringDates){
			if(!perSpotAvailProviders[date] || (perSpotAvailProviders[date]).length == 0){
				notAvailableDates.push(date)
			}
		}
		for(let date in perSpotAvailProviders){
			for(let spot in perSpotAvailProviders[date]){
				let singleProviders = (perSpotAvailProviders[date][spot]).filter((x:any) => (!perSpotAvailTeam[date] || !perSpotAvailTeam[date][spot] || !(perSpotAvailTeam[date][spot]).includes(x)));
				// console.log('difference',date,spot,perSpotAvailProviders[date][spot],'----',singleProviders)
				// if((perSpotAvailProviders[date][spot]).length < minProvidersReq){
				// 	delete perSpotAvailProviders[date][spot];
				// }
				if((singleProviders).length < minProvidersReq && ( !perSpotAvailTeam[date] || !perSpotAvailTeam[date][spot])){
					delete perSpotAvailProviders[date][spot];
				}
				else if((singleProviders).length < minProvidersReq && perSpotAvailTeam[date] && (perSpotAvailTeam[date][spot]).length > 0){
					perSpotAvailProviders[date][spot] = perSpotAvailTeam[date][spot]
				}
			}
			if(Object.keys(perSpotAvailProviders[date]).length == 0){
				notAvailableDates.push(date)
			}
			else{
				perDateAvailSpots[date] = Object.keys(perSpotAvailProviders[date])
			}
		}
		// console.log('perDateAvailSpots 11',perDateAvailSpots,perSpotAvailProviders,perSpotAvailTeam)
		return {"perDateAvailSpots":perDateAvailSpots,"notAvailableDates":notAvailableDates,'perSpotAvailProviders':perSpotAvailProviders}
	}

	/* function to get the offset or range spots based on setting like booking start from start, middle or end of arrival window */
	startOfSlotRange(start:any, end:any, jobBeginTime:any) {
		let extendedLength = 0;
		let startminutes = (Math.floor(start / 100)) * 60;
		let startminutesleft = start % 100;
		let starttotalminutes = startminutes + startminutesleft;
		let endminutes = (Math.floor(end / 100)) * 60;
		let endminutesleft = end % 100;
		let endtotalminutes = endminutes + endminutesleft;
		let minutesdifference = 0;
		if (end == 0) { minutesdifference = 0; }
		else { minutesdifference = endtotalminutes - starttotalminutes; }
		switch (jobBeginTime) {
			case "start": extendedLength = 0;
			break;
			case "middle": if (end == 0) { extendedLength = 0; }
			else { extendedLength = Math.ceil(minutesdifference / 2); }
			break;
			case "end": if (end == 0) { extendedLength = 0; }
			else { extendedLength = Math.ceil(minutesdifference); }
			break;
			default: extendedLength = 0;
			break;
		}
		return extendedLength;
	}

	/**
	 * Function to  convert time in twenty four.
	 */
	timeInTwentyFour(basetime:any, minutes:any) {
		let basetime_minutes: any = (basetime % 100)
		let basetime_hours: any = parseInt(basetime) - parseInt(basetime_minutes);
		let total_minutes: any = parseInt(basetime_minutes) + parseInt(minutes);
		let minutetoslot: any = (Math.floor(total_minutes / 60) * 100) + (total_minutes % 60);
		let total_hours: any = parseInt(basetime_hours) + parseInt(minutetoslot);
		return total_hours;
	}

	/* function to check same day booking conditions and block the dates */
	// checkSameDayLimit(prefilledData:any){
	checkSameDayLimit(){
		let minTimeLimit : any
		let currentDate = dayjs();
		let currentHour : any = currentDate.format('H')
		currentDate = currentDate.startOf('date');
		currentHour = parseInt(currentHour)*100
		let limitDays = parseInt(this.globals.day_before);

		// if(prefilledData){
		// 	minTimeLimit = (dayjs(currentDate).unix())
		// 	return minTimeLimit
		// }
		// console.log(this.globals)
		if(this.globals.allowSameDayBooking == 'no' ){
			if(this.globals.sameDayBookingLimitTimeValue <= currentHour){
				limitDays = limitDays+1
			}
			// minTimeLimit = (dayjs(currentDate).add(limitDays, 'days').unix())+1
			minTimeLimit = (dayjs(currentDate).add(limitDays, 'days').unix())
		}
		if(this.globals.allowSameDayBooking == 'yes' ){
			minTimeLimit = (dayjs(currentDate).unix())
		}
		// console.log('currentHour',currentHour,this.globals.allowSameDayBooking,minTimeLimit,this.globals.sameDayBookingLimitTimeValue,limitDays,currentDate)
		return minTimeLimit
	}
	/**
	 * Function to check is provider assiciated.
	 */
	checkIfProviderAssociated(providerids: any): boolean{
		if(providerids == 0){
			return false;
		}
		return true
	}
	/**
	 * Function to check if provider have already overriden wages for the booking.
	 */
	checkExistingWages(newProvider: any, existingWagesReimbursements: any): any{
		if(existingWagesReimbursements && existingWagesReimbursements.length > 0){
			for(let provider of existingWagesReimbursements){
				if(provider.provider_type == 'team' && provider.members && (provider.members).length > 0){
					for(let member of provider.members){
						if(newProvider && newProvider.members && (newProvider.members).length > 0){
							for(let newProviderMember of newProvider.members){
								if(newProviderMember.id == member.id){
									if(member.wages && member.wages.base_pay != null && (+member.wages.base_pay >= 0 || +member.wages.base_pay == 0)){
										newProviderMember.wages = member.wages;
									}
									if(member.reimbursements){
										newProviderMember.reimbursements  = member.reimbursements;
									}
								}
							}
						}
					}
				}else{
					if(newProvider.id == provider.id){
						if(provider.wages && provider.wages.base_pay != null && (+provider.wages.base_pay >= 0 || +provider.wages.base_pay == 0)){
							newProvider.wages = provider.wages;
						}
						if(provider.reimbursements){
							newProvider.reimbursements  = provider.reimbursements;
						}
					}
				}
			}
		}
		return newProvider
	}
	/**
	 * Function to check is provider editable.
	 */
	isProviderEditable(prefilledData: any, selectedService: any){
		if(prefilledData && (prefilledData.status == 1 || prefilledData.status == 2 || prefilledData.status == 4)){
			return false;
		}else if(prefilledData.status == 9 || prefilledData.status == 3){
			return true;
		}
		else{
			if(selectedService && selectedService.can_customer_edit == "yes"){
				return true;
			}else{
				return false;
			}
		}
	}

	/* Function to get all dates of a recurring schedule, recurring id will be passed here */
	getRecurringScheduleDates(rec_id:number): Observable<any> {
		return this.apiServ.callApiWithPathVariables('GET', 'BookingDates', [rec_id]).pipe(takeUntil(this.destroy));
	}

	/**
	 * Get the default value of apply to all from store settings.
	 * @returns
	 */
	public defValOfApplyToAllFromStoreSett(): string{
		if(this.admnStngs?.merchant_settings?.bookings?.rec_bkng_update_pref == 'this_only'){
			return "false";
		}
		return "true";
	}

}
