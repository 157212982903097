import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ } from '../../../Services';

@Component({
	selector: 'bk-image',
	template: `
	<div [id]="secId"  class="mb-10" *ngIf="section && section?.urls && (section?.urls).length > 0">
		<!-- TODO: to make class for aspect-ratio -->
		<div class="bk-row-image overflow-hidden d-inline-flex" style="aspect-ratio: 1 / 1;">
			<img loading="auto" [src]="section?.urls[0]" class="img-fluid flex-fill " alt="Media" width="" height="">
		</div>
	</div>
	`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class ImageComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	// Section fields
	section: any = null;
	sectionData: any;

	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ) {}

	ngOnInit(): void {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.pageSett = this.sectionData.section_settings;
			this.secServ.setServData(this.pageSett, this.sectionData.content);
			if(this.pageSett && this.pageSett[this.secId] && this.secServ.buildMedia(this.secId)){
				this.section = this.secServ.buildMedia(this.secId);
			}
		}
	}
}