import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
// Interface
import { BkngInvDetailsPrice, Invoice } from 'src/app/Interfaces';
// Services
import { InitServ, LoaderServ, NgOnDestroy, UtilServ } from 'src/app/Services';
import { InvServ } from 'src/app/Session/Invoices';
@Component({
	selector: 'bk-inv-sidebar',
	templateUrl: './InvSidebar.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class InvSidebarComponent implements OnInit, OnChanges {
	// Variables
	@Input() invSidebar: any
	@Input() invData?: Invoice;
	@Input() invForm!: FormGroup;
	@Input() isForm: boolean = true;
	@Input() isInvPayment: boolean = false;
	@Input() bkngAmtData?: BkngInvDetailsPrice;
	@Output() reCalBkngAmt: EventEmitter<boolean> = new EventEmitter<boolean>();
	loaderId: string = 'inv-summary';
	partialPayPercentage: number = 0;
	invFullAmt: number = 0;
	paymentType: string = '';
	summary: {inv_total: number, card_hold: any, advance: number, remaining: number, pay: number, tip: number, total: number} = {
		inv_total: 0,
		card_hold: 0,
		advance: 0,
		remaining: 0,
		pay: 0,
		tip: 0,
		total: 0
	}
	isPlanPermission: boolean;
	//convenience getter for easy access to form fields
	get tipGrp(): FormGroup {
		return <FormGroup>this.invForm.controls['tip'];
	}

	// eslint-disable-next-line max-params
	constructor(public utilServ: UtilServ, private frmBldr: FormBuilder, private invServ: InvServ, private loader: LoaderServ, public initServ: InitServ) {
		this.isPlanPermission = this.initServ.appPlansPermission('Invoice Charges Settings');
	}

	ngOnInit(): void {
		if(this.isForm && this.isAllowPartialAndTip){
			this.invForm.addControl('payment_type', new FormControl('full'));
			this.buildTipGroup();
		}
		if(this.isAllowPartialAndTip || !this.isForm){
			this.calPartialPayPercentage();
		}
		this.buildSummary();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if(this.isChangedExcludingSelectedBkngs(chng, propName) && !this.utilServ.areSameObj(chng.currentValue, chng.previousValue)){
					this.buildSummary();
				}
			}
		}
	}

	/**
	 * Builds the tip form group if tipping is allowed for the current invoice.
	 */
	private buildTipGroup(): void {
		if(this.isTipAllowed()){
			this.invForm.addControl('tip', this.frmBldr.group({
				pay: [null, this.utilServ.maxPriceValidation],
				unit: ['amount']
			}));
		}
	}

	/**
	 * Calculates the percentage of partial payment.
	 * Initializes the partialPayPercentage based on the split payments array.
	 * Adds a control 'percent_of_payment' to the form if applicable.
	 */
	private calPartialPayPercentage(): void {
		// Get the array of split payments
		let splitPayments = this.partialPay;
		// Initialize partial pay percentage
		this.partialPayPercentage = 0;
		if(splitPayments){
			// Check if the invoice data contains information about the number of payments
			if (this.invData?.no_of_payments) {
				let calcIndex: number = this.invData.no_of_payments;
				// Check if calcIndex is within the range of splitPayments array
				if (calcIndex < splitPayments.length) {
					// Set partialPayPercentage based on the index from splitPayments array
					this.partialPayPercentage = splitPayments[calcIndex];
				}
			} else {
				// If no_of_payments is not available, default to the first value in splitPayments
				this.partialPayPercentage = splitPayments[0];
			}
		}
		// If it's a form, add a control 'percent_of_payment' with the value of partialPayPercentage
		if (this.isForm) {
			this.invForm.addControl('percent_of_payment', new FormControl(this.partialPayPercentage));
		}
	}

	/**
	 * Checks if the property has changed and is not the first change,
	 * excluding 'selectedBkngs'.
	 *
	 * @param chng - The SimpleChange object containing change details.
	 * @param propName - The name of the property being checked.
	 * @returns true if the property has changed, is not the first change,
	 *          and is not 'selectedBkngs'; otherwise false.
	 */
	private isChangedExcludingSelectedBkngs(chng: SimpleChange, propName: string): boolean {
		return !chng.firstChange && propName != 'selectedBkngs';
	}

	/**
	 * Builds the summary for the current invoice.
	 * Resets the summary object and calculates various amounts asynchronously.
	 */
	private buildSummary(): void {
		// Reset the summay object
		this.summary = {
			inv_total: 0,
			card_hold: 0,
			advance: 0,
			remaining: 0,
			pay: 0,
			tip: 0,
			total: 0
		}
		this.paymentType = this.getPaymentType();
		setTimeout(() => {
			if(this.invData?.inv_type == 'custom'){
				this.summary.inv_total = this.invServ.calInvTotal(this.invData);
			}
			this.calTotalAmt(true);
		}, 0);
	}

	/**
	 * Retrieves the payment type.
	 * Returns the payment type from the form if partial payment and tip are allowed, otherwise returns the payment type from the invoice data.
	 * @returns {string} The payment type.
	 */
	private getPaymentType(): string {
		if (this.isForm && this.isAllowPartialAndTip) {
			return this.invForm.controls['payment_type'].value;
		}
		return this.invData?.payment_type ? this.invData?.payment_type : 'full';
	}

	/**
	 * Calculates the total amount for the invoice.
	 * Handles different scenarios based on the invoice type(custom/bookings).
	 * @param isFirst {boolean} Indicates if it's the first calculation.
	 * @param isCallback {boolean} Indicates if it's a callback to parent component in case of booking
	 */
	public calTotalAmt(isFirst: boolean = false, isCallback: boolean = false): void {
		this.paymentType = this.getPaymentType();
		if (this.invData?.inv_type == 'bookings') {
			this.handleBkngs(isCallback);
		} else {
			this.handleCustom(isFirst);
		}
		this.calSummaryTip();
		this.summary.total = this.summary.inv_total + this.summary.tip;
		this.loader.hide(this.loaderId);
	}

	/**
	 * Handles bookings for the invoice.
	 * Shows loader and emits re-calculates event if it's a callback.
	 * Otherwise, calculates booking amount.
	  * @param isCallback {boolean} Indicates if it's a callback to parent component in case of booking
	 */
	private handleBkngs(isCallback: boolean): void {
		if (isCallback) {
			this.loader.show(this.loaderId);
			this.reCalBkngAmt.emit(true);
		} else {
			this.bkngCal();
		}
	}

	/**
	 * Calculates the total amount for bookings in the invoice.
	 * Updates various summary amounts based on booking data.
	 */
	private bkngCal(): void {
		this.invBkngFullAmt();
		if(this.bkngAmtData){
			this.summary.inv_total = this.utilServ.roundToTwo(this.bkngAmtData?.remaining + (this.invPaidAmt()));
			this.summary.card_hold = (this.invServ.isObjExist(this.bkngAmtData?.card_hold)) ? this.bkngAmtData.card_hold : 0;
			this.calBkngAdvance();
			this.summary.remaining = this.utilServ.roundToTwo(((this.isInvPayment) ? (this.bkngAmtData?.remaining ?? 0) : this.invFullAmt) - this.summary.advance)
			this.summary.pay = this.utilServ.roundToTwo(this.bkngAmtData?.charge_amount ?? 0);
		}
		// Hide the sidebar if necessary
		this.hideSideBar();
	}

	/**
	 * Calculates the full amount for the invoice based on booking data.
	 */
	private invBkngFullAmt(): void {
		this.invFullAmt = this.bkngAmtData?.total ? this.utilServ.roundToTwo((this.bkngAmtData?.total - this.paidTip()) - this.invPaidAmt()) : 0;
	}

	/**
	 * Retrieves the paid tip amount.
	 * Returns the tip amount if it's not an invoice payment and tip is provided, otherwise returns 0.
	 * @returns {any} The paid tip amount.
	 */
	private paidTip(): any {
		return (!this.isInvPayment && this.invData?.tip?.pay) ? (+this.invData?.tip?.pay) : 0;
	}

	/**
	* Retrieves the paid invoice amount.
	* Returns the paid amount if available, otherwise returns 0.
	* @returns {any} The paid invoice amount.
	*/
	private invPaidAmt(): any {
		return (this.invData?.paid_amount ? this.invData?.paid_amount : 0)
	}

	/**
	 * Calculates and sets the booking advance amount if the payment type is 'partial'.
	 */
	private calBkngAdvance(): void {
		if(this.paymentType == 'partial'){
			this.summary.advance = this.utilServ.roundToTwo((this.bkngAmtData?.charge_amount ?? 0) - this.getTipAmt());
		}
	}

	/**
	* Retrieves the tip amount.
	* Returns the tip amount from the form if partial payment and tipping is allowed, otherwise returns the tip amount from the invoice data.
	* @returns {number} The tip amount.
	*/
	private getTipAmt(): number {
		if(this.isAllowPartialAndTip){
			if (this.isForm && this.isTipAllowed()) {
				return (+this.tipGrp.controls['pay'].value) ? (+this.tipGrp.controls['pay'].value) : 0;
			} else if(this.invServ.bkngsTipAmt && this.invData?.settings?.tip_settings?.pay_type === 'specific'){
				return this.invServ.bkngsTipAmt;
			}
		}
		return this.getInvTipAmt();
	}

	/**
	 * Checks if tip is allowed for the current invoice.
	 * Tipping is allowed if:
	 * - Custom tip is enabled by the customer
	 * - There are no payments made yet
	 * - The invoice type is 'custom' or the tip pay type is set to 'combine'
	 * @returns {boolean} true if tipping is allowed, false otherwise.
	 */
	private isTipAllowed(): boolean | undefined {
		let { allow_tip_by_cust, tip_settings } = this.invData?.settings || {};
		let { inv_type, no_of_payments = 0 } = this.invData || {};
		return (
			allow_tip_by_cust && !no_of_payments && (inv_type === 'custom' || tip_settings?.pay_type === 'combine')
		);
	}

	private getInvTipAmt(): number {
		return (!this.invData?.paid_amount && this.invData?.tip?.pay) ? (+this.invData.tip.pay) : 0;
	}

	/**
	 * Hide the sidebar if inv_total and pay amount is same
	 */
	private hideSideBar(): void {
		let sett = this.invData?.settings;
		// Hide the side bar
		if(this.allowPartialAndTip(sett) && this.arePayAndInvTotalEqual()){
			this.invServ.hideSidebar = true;
		}
	}

	private allowPartialAndTip(sett: any): boolean {
		return (!sett?.allow_partial_pay && !sett?.allow_tip_by_cust);
	}

	/**
	 * Checks if the payment amount and invoice total are the same.
	 *
	 * @returns true if the payment amount and invoice total are equal; otherwise false.
	 */
	private arePayAndInvTotalEqual(): boolean {
		return (this.summary?.pay && this.summary.inv_total == this.summary.pay) ? true : false;
	}

	/**
	 * Calculates and sets the summary tip amount.
	 * If no tip is calculated, it attempts to use the paid amount and tip from the invoice data.
	 */
	private calSummaryTip(): void {
		this.summary.tip = this.getTipAmt();
		if(!this.summary.tip){
			if(this.invData?.paid_amount && this.invData?.tip?.pay){
				this.summary.tip = this.invData.paid_amount && this.invData.tip.pay;
			}
		}
	}

	/**
	 * Handles custom invoice calculations.
	 * Shows loader, initializes advance amount to 0, and performs custom calculations.
	 * @param isFirst {boolean} Indicates if it's the first calculation.
	 */
	private handleCustom(isFirst: boolean): void {
		this.loader.show(this.loaderId);
		this.summary.advance = 0;
		this.customCal(isFirst);
	}

	/**
	 * Performs custom calculations for the invoice.
	 * Calculates remaining total, advance payment, total payment including tip, and updates sidebar visibility.
	 * @param isFirst {boolean} Indicates if it's the first calculation.
	 */
	private customCal(isFirst: boolean): void {
		// Calculate remaining
		this.summary.remaining = this.invServ.calInvRemainingTotal(this.invData);
		if (isFirst) {
			this.invFullAmt = this.summary.remaining;
		}
		let payAmt: number = 0;
		// Calculate based on payment type (partial or full)
		if (this.paymentType == 'partial') {
			// Calculate advance payment for partial payments
			this.summary.advance = this.utilServ.roundToTwo((this.summary.inv_total * this.partialPayPercentage) / 100);
			payAmt = this.summary.advance;
		} else {
			payAmt = this.summary.remaining;
		}
		// Calculate total payment including tip
		this.summary.pay = this.utilServ.roundToTwo(payAmt + this.getTipAmt());
		// Calculate remaining
		if (payAmt != this.summary.remaining) {
			this.summary.remaining = this.utilServ.roundToTwo(this.summary.remaining - payAmt);
		}
		this.hideSideBar();
	}

	/**
	 * Checks if partial payment and tip are allowed for the current invoice.
	 * Partial payment and tip are allowed if:
	 * - Partial payment is enabled
	 * - Tip is allowed by the customer
	 * @returns {boolean} true if partial payment or tip is allowed, false otherwise.
	 */
	get isAllowPartialAndTip(): boolean {
		return (
			this.invData?.settings?.allow_partial_pay ||
			this.invData?.settings?.allow_tip_by_cust
		) ? true : false;
	}

	/**
	 * Retrieves the partial payment split percentage based on the invoice settings.
	 * Returns the split percentage from settings if partial payment and tip is allowed in the form.
	 * Otherwise, returns the split percentage from the invoice data.
	 * @returns {any} The split percentage for partial payment.
	 */
	get partialPay(): any {
		return this.isAllowPartialAndTip && this.invData?.settings?.partial_pay?.split_percentage;
	}
}
